import { twMerge } from "tailwind-merge";
import "./index.css";
export default function PageLoader({ className = "" }) {
  return (
    <>
    <div className="bg-primary h-screen w-screen flex items-center justify-center">
      <div className="banter-loader">
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
        <div className={twMerge("banter-loader__box", className)}></div>
      </div>
    </div>
    </>
  );
}
