const ImageLoader = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="w-5 h-5 rounded-full animate-pulse bg-blue-100"></div>
      <div className="w-5 h-5 rounded-full animate-pulse bg-blue-200"></div>
      <div className="w-5 h-5 rounded-full animate-pulse bg-blue-300"></div>
    </div>
  );
};

export default ImageLoader;
