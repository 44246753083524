import { useNFT } from 'context/nft';
import { useUser } from 'context/user';
import React from 'react'
import { useDisconnect } from 'wagmi';
const WalletDisconnect = ({setPopupOpen}) => {
    const { clearNFTCollection } = useNFT();
    const {clearUser} =useUser();
    const { disconnect } = useDisconnect({
        onSuccess(data) {
          clearNFTCollection();
          clearUser();
        },
      });
  return (
    <div>
        <div className="p-0 md:p-8 h-fit">
        <p className="text-[#02071A] mb-16 md:mb-20 md:leading-[42px] opacity-80 text-center text-2xl md:text-[28px] font-[600] w-full max-w-sm">
          Are you sure you want to disconnect your wallet?
        </p>

        <div className="flex items-center justify-between">
          <button
            onClick={() => setPopupOpen(false)}
            className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-3 md:px-[22px] py-3 md:py-[14px] text-base md:text-[18px] font-[600]"
          >
            No, Cancel
          </button>
          <button
            onClick={() => {
              disconnect();
            }}
            className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-3 md:px-4 py-3 md:py-[14px] text-base md:text-[18px] font-[600]"
          >
            Yes, Disconnect
          </button>
        </div>
      </div>
    </div>
  )
}

export default WalletDisconnect