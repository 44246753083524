import React, { useState } from "react";
import ReactDOM from "react-dom";
import Cross from "components/Cross";

function Popup({ isOpen, onClose, showCloseIcon = true, children }) {
  const [isMounted, setIsMounted] = useState(false);

  React.useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => (document.body.style.overflow = "auto");
  }, [isOpen]);



  return isMounted
    ? ReactDOM.createPortal(
        isOpen ? (
          <div className="fixed inset-0 flex items-center z-50 justify-center">
            <div className="fixed inset-0 bg-black  opacity-50"></div>
           <div className="z-50 h-full p-10 hide-scrollbar overflow-y-scroll">
           <div
              style={{
                boxShadow: "0px 0px 32px 0px rgba(255, 255, 255, 0.16)",
              }}
              className="bg-white p-4 rounded-[24px] z-50"
            >
              <div className="h-full overflow-hidden ">
              <div className="max-w-[calc(100dvw-50px)] md:max-w-[760px] md:overflow-y-auto overflow-y-scroll">
                <div
                  onClick={onClose}
                  className="flex  top-0 bg-white z-50  py-3 px-5 cursor-pointer justify-end items-center"
                >
                  {showCloseIcon ? <Cross /> : ''}
                </div>
                {children}
              </div>
              </div>
            </div>
           </div>
          </div>
        ) : null,
        document.body
      )
    : null;
}

export default Popup;
