
import axios from 'axios';

export const purpledex = async (walletAddress, collection) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/user/userdex`, {
            walletAddress: walletAddress,
            collection: collection,
        });
        return response.data.collection;
    } catch (error) {
        throw new Error(error);
    }
};