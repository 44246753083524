import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Boxloader, NFT } from "common";
import axios from "axios";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNFT } from "context/nft";
import Popup from "components/Popup";
import { useSocket } from "context/socket";
import { useUser } from "context/user";
import { FixedSizeGrid as Grid } from "react-window";
import { NFT as NFTSkeleton } from "components/skeleton/NFT";
import "./GridStyles.css";

export default function NFTsection() {
  const { address } = useAccount();
  const [stakeloading, setstakeloading] = useState("");
  const { nftCollection, updateNFTCollection } = useNFT();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedtextpopup, setselectedtextpopup] = useState("");
  const [loadingPaynow, setloadingPaynow] = useState(false);

  const { socket } = useSocket();

  const [evolvingNftId, setEvolvingNftId] = useState(null); // Tracks which NFT is evolving
  const [loaderImageUrl, setLoaderImageUrl] = useState(""); // URL for the loader image

  // State to hold the calculated column count, grid width, and row height
  const [gridConfig, setGridConfig] = useState({
    columnCount: 4,
    width: 1000, // Default grid width
    rowHeight: 320, // Default row height
    height: 620, // Default grid height
  });

  const { user, refetch } = useUser();

  const isEvolveActive = useMemo(() => {
    const currentDate = new Date().getTime();
    return user?.evolveDate
      ? Math.abs(
          Math.ceil(
            (new Date(user?.evolveDate).getTime() - currentDate) /
              (1000 * 60 * 60 * 24)
          )
        ) < 31
      : false;
  }, [user]);

  const stakeClick = useCallback(
    async (label, id) => {
      try {
        setstakeloading(label);
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}/nft/addNFTStake`, {
            nftId: label,
            userId: id,
          })
          .then((data) => data.data)
          .then((data) => {
            if (data.statusCode === 200) {
              updateNFTCollection(data.data);
            } else {
              toast.error(data?.message || "Failed to Stake", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setstakeloading("");
          });
      } catch (error) {
        setstakeloading("");
        toast.error(error?.response?.data?.message || "Failed to Stake", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
    [updateNFTCollection]
  );

  const handleUnStake = useCallback(
    async (label, id) => {
      try {
        setstakeloading(label);
        await axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}/nft/unstake`, {
            nftId: label,
            userId: id,
          })
          .then((data) => data.data)
          .then((data) => {
            if (data.statusCode === 200) {
              updateNFTCollection(data.data);
            } else {
              toast.error(data?.message || "Failed to Unstake", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setstakeloading("");
          });

        // setgeneralTrigger(!generalTrigger);
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
    [updateNFTCollection]
  );

  // Filter NFTs to exclude any that are burned or where the 'burned' field is not present (considered as not burned)
  const filteredNFTCollection = useMemo(() => {
    return nftCollection.filter((nft) => nft.burned !== true);
  }, [nftCollection]);

  const stakedNft = nftCollection.filter((nft) => nft.stakeStatus === true);

  // Render function for Grid
  const Cell = ({
    columnIndex,
    rowIndex,
    style,
    data,
    isScrolling,
    columnCount,
  }) => {
    const nftIndex = rowIndex * columnCount + columnIndex;
    const nft = filteredNFTCollection[nftIndex];

    // Render the skeleton if the list is scrolling
    // if (showSkeleton || isScrolling) {
    if (isScrolling) {
      return (
        <div style={{ ...style }} className="flex justify-center">
          <NFTSkeleton />
        </div>
      );
    }

    console.log("NFTsection->Cell->NFT: ", nft);
    
    return nft ? (
      <div style={{ ...style }} className="flex justify-center item">
        <NFT
          label={nft.id}
          imageUrl={nft.image}
          nft={nft}
          stakeClickfrombackend={stakeClick}
          handleUnStakefrombackend={handleUnStake}
          evolveafter30dayfrombackend={evolveafter30day}
          stakeloading={stakeloading}
          allGreen={stakedNft.length >= 5 || isEvolveActive}
          checkout={checkout}
          isEvolving={evolvingNftId === nft.id}
          loaderImageUrl={evolvingNftId === nft.id ? loaderImageUrl : ""}
          isScrolling={isScrolling}
        />
      </div>
    ) : null;
  };

  const evolveafter30day = useCallback(
    async (label, id) => {
      try {
        setEvolvingNftId(label);
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}/nft/evolve`, {
            nftId: label,
            userId: id,
          })
          .then((data) => data.data)
          .then((data) => {
            if (data.statusCode === 200) {
              setLoaderImageUrl(data.data.loaderImageUrl); // Set to loader image
              setTimeout(() => {
                setLoaderImageUrl(""); // Clear loader image after 3 seconds
                setEvolvingNftId(null); // Reset evolving NFT id
              }, 6000);

              updateNFTCollection(data.data);
            } else {
              toast.error(data?.message || "Failed to evolve", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
        // setgeneralTrigger(!generalTrigger);
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
    [updateNFTCollection]
  );

  useEffect(() => {
    if (socket == null) return;

    socket.on("transaction_update", (data) => {
      if (data.type === "evolve") {
        if (data.status === "success") {
          setselectedtextpopup("congratulation");
          setPopupOpen(true);
          refetch();
        }
        if (data.status === "failed") {
          setselectedtextpopup("failed");
          setPopupOpen(true);
        }
      }
    });

    return () => {
      socket.off("transaction_update");
    };
  }, [socket]);

  let data = {
    loading: (
      <div className="md:px-6 overflow-y-hidden max-w-lg">
        <div className="flex justify-center items-center ">
          <Boxloader />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center w-full">
          <p className="text-[#02071A] text-sm md:text-lg font-[600] pt-16 pb-10 text-center">
            {loadingPaynow ? (
              "Please wait while we redirect you to payment page..."
            ) : (
              <span>
                Please wait until we process your transaction...
                <br />
                Do not refresh or close your browser.
              </span>
            )}
          </p>
        </div>
      </div>
    ),
    pending: (
      <div className="px-6 overflow-y-hidden max-w-lg">
        <div className="flex justify-center items-center ">
          <Boxloader />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center w-full">
          <p className="text-[#02071A] text-sm md:text-3xl font-[600] pt-16 pb-10 text-center">
            Transaction pending confirmed. Please allow 5-10 minutes for
            confirmation.
          </p>
        </div>
      </div>
    ),
    congratulation: (
      <div className="px-4 md:px-[78px] overflow-y-hidden  max-w-lg">
        <div className="flex justify-center items-center ">
          <img
            className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
            src="./success.svg"
            alt=""
            srcset=""
          />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center">
          <p className="text-[#02071A] text-[28px] font-[600] pt-10 md:pt-[56px] pb-[40px]">
            Congratulations!
          </p>
          <p className="text-[#02071A] text-sm md:text-[15px] text-center font-[500]">
            Now you can evolve your squares instantly for next 30 days.
          </p>
        </div>
      </div>
    ),
    failed: (
      <div className="px-2 md:px-[20px] overflow-y-hidden  max-w-lg">
        <div className="flex justify-center items-center ">
          <img
            className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
            src="./failed.svg"
            alt=""
            srcset=""
          />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center">
          <p className="text-[#02071A] text-xl md:text-[28px] font-[600] pt-10 md:pt-[56px] pb-[25px] text-center">
            Sorry, Payment Failed!
          </p>
          <p className="text-[#02071A] opacity-80 text-xs md:text-[18px] font-[500] text-center">
            Please try to pay the evolution fee again, to instantly evolve your
            squares.
          </p>
          <div className="flex pt-12 items-center justify-between  pb-3 bg-white w-full">
            <button
              onClick={() => setPopupOpen(false)}
              className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-4 md:px-[22px] py-2.5 md:py-[14px] text-[18px] font-[600]"
            >
              Cancel
            </button>
            <button
              onClick={() => checkout()}
              className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-6 md:px-[52px] py-2.5 md:py-[14px] text-[18px] font-[600]"
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    ),
    paymentFromMobile: (
      <div className="px-6 overflow-y-hidden max-w-lg">
        <div className="flex justify-center items-center ">
          <img
            className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
            src="./failed.svg"
            alt=""
            srcset=""
          />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center w-full">
          <p className="text-[#02071A] text-sm md:text-3xl font-[600] pt-16 pb-10 text-center">
            Please complete your payment from a laptop/desktop device.
          </p>
        </div>
      </div>
    ),
  };

  function checkout() {
    if (window.innerWidth < 768) {
      setselectedtextpopup("paymentFromMobile");
      setPopupOpen(true);
      return;
    }
    const windowReference = window.open();
    try {
      setloadingPaynow(true);
      setselectedtextpopup("loading");
      setPopupOpen(true);

      axios
        .post(`${process.env.REACT_APP_PUBLIC_URL}/checkout`, {
          amount: 100,
          currency: "usd",
          userId: address,
          type: "evolve",
          twitterName: localStorage.getItem("UserName")
            ? JSON.parse(localStorage.getItem("UserName")).screenName
            : "",
        })
        .then((res) => res.data)
        .then(({ charge }) => {
          if (!windowReference) {
            alert("Please allow popups for this website");
            return;
          }
          if (charge) {
            windowReference.location = charge.hosted_url;
          } else {
            windowReference.close();
          }
          setloadingPaynow(false);
        })
        .catch((err) => {
          console.error(err);
          setloadingPaynow(false);
        });
    } catch (error) {
      console.error(error);
      setloadingPaynow(false);
    }
  }

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      let columnCount;
      let gridWidth;
      let rowHeight;
      let gridHeight = 620;

      if (screenWidth > 1430) {
        columnCount = 4;
        gridWidth = 1000;
        rowHeight = 320;
        gridHeight = 620;
      } else if (screenWidth > 1300) {
        columnCount = 3;
        gridWidth = 800;
        rowHeight = 320;
      } else if (screenWidth > 1000) {
        columnCount = 2;
        gridWidth = screenWidth - 570; // Subtract some padding
        rowHeight = 320;
      } else if (screenWidth > 768) {
        columnCount = 3;
        gridWidth = screenWidth - 40; // Subtract some padding
        rowHeight = 320;
      } else if (screenWidth > 640) {
        columnCount = 2;
        gridWidth = screenWidth - 20; // Subtract some padding
        rowHeight = 320;
      } else {
        columnCount = 2;
        gridWidth = screenWidth - 20; // Subtract some padding
        rowHeight = 235; // You can adjust this value to suit your design
        gridHeight = screenWidth > 640 ? 640 : 450;
      }

      setGridConfig({
        columnCount,
        width: gridWidth,
        rowHeight,
        height: gridHeight,
      });
    }

    // Set the initial sizes
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        children={data[selectedtextpopup]}
        showCloseIcon={
          !(selectedtextpopup === "loading" || selectedtextpopup === "pending")
        }
      />
      <div className="flex flex-nowrap gap-x-10 justify-center items-center gap-y-10">
        <Grid
          className="scrollbar"
          columnCount={gridConfig.columnCount}
          columnWidth={gridConfig.width / gridConfig.columnCount}
          height={gridConfig.height} // You may want to adjust this as well based on your design requirements
          rowCount={Math.ceil(
            filteredNFTCollection.length / gridConfig.columnCount
          )}
          rowHeight={gridConfig.rowHeight}
          width={gridConfig.width}
          itemData={filteredNFTCollection}
          useIsScrolling={true}
        >
          {({ columnIndex, rowIndex, style, isScrolling }) => (
            <Cell
              columnIndex={columnIndex}
              rowIndex={rowIndex}
              style={style}
              data={filteredNFTCollection}
              isScrolling={isScrolling}
              columnCount={gridConfig.columnCount}
            />
          )}
        </Grid>
      </div>
    </>
  );
}
