import React from "react";
import CommunityCard from "../../components/CommunityCard.jsx";
import { motion } from "framer-motion";

function Community() {
  return (
    <div className="bg-[#F6F7FB] ">
      <div className="pt-[80px] md:pb-[80px] pb-[52px] px-[20px] md:px-[10px]">
        <div className="space-y-[16px]">
          <motion.p
            initial={{ opacity: 0, x: -150, rotate: 0 }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="font-[400] text-[32px] text-center"
            style={{ color: "rgba(2, 7, 26, 0.80)" }}
          >
           Welcome to <span className="font-[600]">627EEA</span>{" "}
            
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: -100, rotate: 0 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-[#02071A] px-3 leading-relaxed lg:w-[65%] mx-auto text-center font-[400] text-[16px] opacity-[0.7]"
          >
            Unlock a world of exciting possibilities and exclusive benefits. Here's how you can become a valued member:
          </motion.p>
        </div>
        <motion.div className="flex flex-wrap items-end justify-center gap-x-[24px] flex-col md:flex-row mt-[32px] space-y-[24px]">
          <CommunityCard
            t1="Own at Least One NFT"
            imge="/sl.svg"
            t2="Owning at least one of our stunning NFTs is the key to retaining your access to the network. NFT ownership establishes your active role in the community and opens doors to exciting opportunities."
          />
          <CommunityCard
            t1="Evolve Your NFT"
            imge="/final chicken evolution.gif"
            t2="Every single square is capable of evolving into something special. These evolutions are unique and dependent on the egg you reveal. There are 30 unique eggs to collect and 5 very special eggs."
          />
          <CommunityCard
            t1="Collect & Connect"
            imge="/community section.png"
            t2="Every breed has unique benefits that you can eventually leverage to earn rewards. The more breeds in your collection, the better. Remember to choose your friends and enemies wisely. You may need to join forces..."
          />
        </motion.div>
      </div>
    </div>
  );
}

export default Community;
