import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Faqs() {
  const [questions, setQuestions] = useState([
    {
      question: "What is 627EEA?",
      answer:
        "627EEA is an organization created to establish color consensus. Each square is a representation of membership. Metadata can evolve to reveal special changes in your digital identity.",
      pm: false,
      bollet: false,
      link: false,
    },
    {
      question: "Is it blue or is it purple?",
      answer:
        "Great question. To some, it is blue. To others, it is purple. Does it matter? No. Whatever color it feels to you, then it is. If it gets people talking, then we’re winning.",
      pm: false,
      bollet: false,
      link: false,
    },
    {
      question: "What are the evolutions?",
      answer:
        "Every square has the capability to evolve into something special. There are many different evolutions to go through. You can evolve your squares in a few different ways.",
      pm: false,
      bollet: false,
      link: false,
    },
    {
      question: "How can I evolve my square?",
      answer:
        "There are three methods to evolving, this depends on how many squares you hold and stake. For anyone who stakes more than 5 squares at a time, you can evolve for free. If you own less than 5 squares you have two other options. You can stake your square(s) for 30 days then evolve them OR pay an evolution fee to instantly get access to evolution rights to your square(s).        ",
      pm: false,
      bollet: false,
      link: false,
    },
    {
      question: "Is the evolution fee a one-time payment or monthly?",
      answer:
        "It is a monthly fee for anyone who stakes less than 5 squares. You are not required to pay this fee if you are willing to stake your squares for 30 days. This will unlock the evolution. The only way to avoid this fee is by staking more than 5 squares or waiting 30 days to evolve.        ",
      pm: false,
      bollet: false,
      link: false,
    },
    {
      question: "Are there any benefits to owning more than one?      ",
      answer:
        "Yes. Avoiding the evolution fee and wait time. Collecting more breeds + many other benefits that will serve a holder's benefit.",
      pm: false,
      bollet: false,
      link: false,
    },
    {
      question: "Where can I get support if I need questions answered?      ",
      answer:
        "If you're a holder, you have access to the support ticket channel and are able to open a ticket whenever assistance is needed. We will respond to everyone’s ticket in a timely manner, please be patient!        ",
      pm: false,
      bollet: false,
      link: false,
    },
    // {
    //   question: "Do I need X blue to get a badge?",
    //   answer:
    //     "No. X blue is NOT required to get an affiliate badge. In fact, as soon as we give you an affiliate badge, if you’re not verified you will become verified INSTANTLY. Yes, right away. As soon as you accept the invite to join.\n\nThe affiliate badge has extra privileges that regular X blue users do not have, which is why this is also worth having if you could benefit from those.",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
    // {
    //   question: "How do I get an affiliate badge next to my name?",
    //   answer:
    //     "You must own at least one 627EEA NFT + pay the evolution fee every month.",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
    // {
    //   question: "Is the evolution fee a one-time payment or monthly?",
    //   answer:
    //     "This evolution fee is to cover the charges that X requires for each user to be added as an affiliate to the network. We add a $5 fee to cover the swap fees as we only take payments in crypto. (ETH, USDC, USDT)",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
    // {
    //   question: "Is the evolution fee a one-time payment or monthly?",
    //   answer:
    //     "This evolution fee is to cover the charges that X requires for each user to be added as an affiliate to the network. We add a $5 fee to cover the swap fees as we only take payments in crypto. (ETH, USDC, USDT)",
    //   pm: true,
    //   bollet: true,
    //   link: false,
    // },
    // {
    //   question:
    //     "How long will it take to get the affiliate badge next to my name?",
    //   answer:
    //     "Most applications are processed the same day or at max within 48 hours of payment confirmation. If it’s been longer than 48 hours and you haven’t received an invite or instructions on when you will be, just open a ticket in the Discord and we will get you sorted. \n\n Keep in mind, this is a manual process and you will not get the badge automatically after paying the affiliate fee. Please be patient while we process your invite.",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
    // {
    //   question: "Are there any benefits to owning more than one?",
    //   answer: "Yes.",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
    // {
    //   question: "Will I be allowed to purchase more than one affiliate badge?",
    //   answer: "Yes.",
    //   pm: true,
    //   bollet: false,
    //   link: true,
    // },
    // {
    //   question:
    //     "You mentioned developments are coming, can you go more in-depth on this?",
    //   answer:
    //     "Yes. Developments are being prepared for the first evolution of the squares and the journey ahead. This user-interface will be live once the evolutions begin and allow our users to connect their wallets, see their squares, make their evolution payments and add other users if they wish to. This system is going to change in time as we add other features and the project evolves. The initial layout will still allow for a more seamless process and more onboarding of users into the ecosystem. As time goes on the goal is to make the entire evolution process more automated so that way users can receive their affiliate badges as quickly as possible and also be removed as quickly as possible when becoming ineligible.",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
    // {
    //   question: "Where can I get support if I need questions answered?",
    //   answer:
    //     "If you're a holder, you have access to the support ticket channel and are able to open a ticket whenever assistance is needed. We will respond to everyone’s ticket in a timely manner, please be patient (:",
    //   pm: true,
    //   bollet: false,
    //   link: false,
    // },
  ]);
  const handleTogglePM = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].pm = !updatedQuestions[index].pm;
    setQuestions(updatedQuestions);
  };
  return (
    <div id="faq" className="bg-[#F6F7FB] px-[20px] pt-[120px]">
      <p
        className="text-[24px] text-center"
        style={{ color: "rgba(2, 7, 26, 0.80)" }}
      >
        <span className="font-[600] capitalize">
          Frequently asked questions
        </span>
      </p>
      <p className="text-center text-[#02071A] text-[13px]  md:text-[16px] font-[400] opacity-70 pt-[16px]">
        Answering common questions regarding 627EEA.
      </p>

      {/* toggle */}
      <motion.div
        initial={{ opacity: 0, x: 100, rotate: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className="flex flex-col justify-center items-center mt-[40px] space-y-[16px] md:mx-[46px]"
      >
        {questions?.map((question, index) => (
          <motion.div
            onClick={() => handleTogglePM(index)}
            key={index}
            // style={{ boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.08)" }}
            className={`flex w-full mx-[96px] transition-all transform duration-[350ms] ease-in-out flex-col justify-between border-[#627EEA] border-[2.3px] rounded-[24px] p-[20px]`}
          >
            <div className="flex items-center justify-between">
              <p className={`text-[17px] w-fit font-[500] text-[#02071A]`}>
                {question.question}
              </p>
              <div className="w-[14px]">
                <img src={question.pm ? "/minus.svg" : "/plus.svg"} alt="" />
              </div>
            </div>
            {question.pm && (
              <motion.div
                // initial={{ opacity: 0, y: 100, rotate: 0 }}
                // whileInView={{
                //   opacity: 1,
                //   y: 0,
                // }}
                // viewport={{ once: true }}
                // transition={{ duration: 1 }}
                className={`mt-3  ${
                  question.pm ? "max-h-[500px] overflow-hidden" : "max-h-0"
                }`}
              >
                {question.bollet === true ? (
                  <ul class="max-w-md whitespace-nowrap space-y-1 opacity-90 text-[14px] font-[400] text-[#02071A] list-disc list-inside dark:text-gray-400">
                    <li>
                      Instant verification that can’t be removed so long as you
                      have a badge
                    </li>
                    <li>Increased engagement/impressions</li>
                    <li>
                      Change your profile picture as much as you'd like without
                      losing check
                    </li>
                    <li>
                      Change your username as much as you’d like without losing
                      check
                    </li>
                    <li>Avoid the 30 day waiting period for new accounts</li>
                    <li>Get increased hash limit rates</li>
                    <li>Connected to a network of like-minded individuals</li>
                    <li>
                      NFT backed evolution experience that adds value with every
                      payment you make
                    </li>
                  </ul>
                ) : question.link === true ? (
                  <p className="opacity-90 text-[14px] leading-[24px] font-[400] text-[#02071A]">
                    Yes. However, every X account needs to pay for an evolution
                    to be eligible. You can find the evolution fee payment form
                    where you fill out your information on our website. <br />
                    <br />
                    Go to our website > Press{" "}
                    <Link to="/connect">
                      {" "}
                      <span className="text-[#627EEA] underline">
                        ‘Verify Membership’
                      </span>{" "}
                    </Link>
                    > Fill out Information & Make Payment to the wallet on the
                    form > Wait to be invited to the affiliate network.
                  </p>
                ) : (
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    className="opacity-90 text-[14px] leading-[18px] font-[400] text-[#02071A]"
                  >
                    {question.answer}
                  </p>
                )}
              </motion.div>
            )}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default Faqs;
