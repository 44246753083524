
import axios from 'axios';
import { useUser } from 'context/user';
import { useMemo } from 'react';
import { toast } from 'react-toastify';



export const handleAddUser = async (username) => {
  if (username.trim() !== "" && username.startsWith("@")) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/user/verify-twitter-id`,
        {
          username,
        }
      );
      toast.success("User added!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.error(error);
      toast.error("This user is not linked with Twitter", {
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }
  }
};



//handle month functionality
export const handleMonth = (e, id, setSelectedMonth, setbuttonid) => {
  setSelectedMonth(e);
    setbuttonid(id);
  };

export  const handleCheckboxChange = (event,setIsChecked) => {
    setIsChecked(event.target.checked);
  };
// function to get the currrent date
export const  CurrDate = (setDate) =>{
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
  
    if (currentDay >= 21 && currentDay <= 29) {
      setDate(true);
    } else {
      setDate(false);
    }
}

