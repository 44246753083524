import React from 'react'

const WalletSmallCircleIcon = () => {
  return (
    <div><div>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <circle
      cx="4"
      cy="4"
      r="4"
      fill="#11D885"
    />
    </svg>
    </div></div>
  )
}

export default WalletSmallCircleIcon;