import { Button } from 'common'
import { Arrow } from 'icons'
import React from 'react'

const Boost = ({date,setSelectedTextPopup}) => {
  return (
    <div> 
        <div className="px-2 md:px-6 flex flex-col justify-center items-center">
          {/* {date ? ( */}
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-center text-primary text-3xl md:text-[40px] font-extrabold font-poppins capitalize leading-10 md:leading-[56px] w-full max-w-[310px] md:max-w-[420px]">
                BECOME A BOOSTED MEMBER OF 627EEA!
              </h1>
              <h3 className="text-center text-slate-950 text-lg font-bold font-poppins eading-7 mt-6 md:mt-8 w-full max-w-[310px] md:max-w-[420px]">
                Sign up to become a boosted member and get access to exclusive
                discounts and services offered by the 627EEA organization.
              </h3>
              <p className="text-center text-slate-950 text-base md:text-lg font-semibold font-poppins mt-4 md:mt-6">
                Boosted Membership Fee: $60
              </p>
              <p className="text-center text-slate-950 text-xs font-normal font-poppins mt-10 md:mt-20 w-full max-w-[310px] md:max-w-[420px]">
                This is not a subscription, to remain an active boosted member you
                will need to pay every 28 days. You will not be charged
                automatically.
              </p>
              <Button
                className="text-white bg-primary border-primary rounded-xl px-6 my-8"
                onClick={() => setSelectedTextPopup("terms")}
              >
                Terms of Services <Arrow />
              </Button>
            </div>
          {/* // ) : (
          //   <div>
          //     <div className="flex justify-center items-center ">
          //       <img 
          //         className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
          //         src="./failed.svg"
          //         alt=""
          //         srcset=""
          //       />
          //     </div>
          //     <p className="text-[#02071A] text-xl md:text-[28px] font-[600] pt-10 md:pt-[56px] pb-[25px] text-center">
          //       Sorry!
          //     </p>
          //     <p className="text-xl pb-10">You can pay from 21st to 28th of month.</p>
          //   </div>
          // )}*/}
        </div>
      </div>
  )
}

export default Boost