import React from "react";

import TableBenefits from "../../components/TableBenefits.jsx";
import SecondBenefitsCard from "../../components/SecondBenefitsCard.jsx";
import OptionCard0 from "../../components/OptionCard0.jsx";
import OptionCard1 from "../../components/OptionCard1.jsx";
import OptionCard2 from "../../components/OptionCard2.jsx";
// import Makemoney from "components/Makemoney.jsx";
import Plus from "components/Plus.jsx";
import Questions from "components/Questions.jsx";
import BlueBox from "components/BlueBox.jsx";
import { motion } from "framer-motion";

function Benefits() {
  return (
    <div id="become-purple" className="bg-[#FFF]">
      <div>
        {/* <p
          className="font-[400] capitalize pt-[80px] pb-[40px] text-[32px] text-center"
          style={{ color: "rgba(2, 7, 26, 0.80)" }}
        >
          The <span className="font-[600]">benefits</span> of{" "}
          <br className="md:hidden" />{" "}
          <span className="font-[600]">Owning</span> a badge <br />{" "}
        </p>
        <div className="pb-[80px]">
          <TableBenefits />
        </div> */}

        <div className="bg-[#F6F7FB]">
          <motion.p
            initial={{ opacity: 0, x: -150, rotate: 0 }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="font-[400] pt-[px] pb-[54px] leading-12 px-3 text-[22px] md:text-[32px] text-center"
            style={{ color: "rgba(2, 7, 26, 0.80)" }}
          >
            <span className="font-[600]">
              Creating unique experiences <br className="md:hidden" />{" "}
            </span>{" "}
            through <span className="font-[600]">color.</span>
          </motion.p>
          <motion.div
            initial={{ opacity: 0, x: -100, rotate: 0 }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="flex flex-wrap justify-center items-end space-y-[24px] pb-[80px]"
          >
            <SecondBenefitsCard
              img="/all_the_eggs_final.png"
              t1="Collect"
              t2="Collecting as many breeds as possible will serve to your benefit in future experiences. If you can’t collect more than you own, find allies to partner up with that have what you need."
            />
            <SecondBenefitsCard
              img="/birds.png"
              t1="Evolve"
              t2="Some believe your squares are just that… squares and nothing else. You must evolve your squares to unlock the mystery of your true form. Every square has the potential to evolve into something special. Evolve to find out."
            />
            <SecondBenefitsCard
              img="/pvp square.png"
              t1="Experience"
              t2="Challenge your skills and earn rewards for participating. These experiences will require certain stages of evolutions in order to be eligible to compete."
            />
          </motion.div>
        </div>

        <div id="evolution" className="#FFF">
          <div className="flex flex-col items-center pb-[80px] justify-center px-[20px] ">
            <motion.p
              initial={{ opacity: 0, x: -50, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="font-[400] pt-[80px] text-[32px] text-center"
              style={{ color: "rgba(2, 7, 26, 0.80)" }}
            >
              {" "}
              Unravel the
              <span className="font-[600]"> Mystery</span> of{" "}
              <span className="font-[600]"> Evolution</span>
            </motion.p>
            <motion.p
              initial={{ opacity: 0, x: -50, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="text-center text-[#02071A] mt-[16px] mb-[40px] text-[16px] font-[400] opacity-70 pt-[16px]"
            >
              Your squares are more than just squares… <br />
              Every square has the capability to evolve into something special
              :){" "}
            </motion.p>
            <div className="hidden lg:flex flex-wrap gap-y-6 gap-x-6 justify-between items-center">
              <motion.div
                initial={{ opacity: 0, x: -50, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 0.2 }}
              >
                <BlueBox src={null} />
              </motion.div>
              <motion.img
                initial={{ opacity: 0, x: -70, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                src="/go.svg"
                alt=""
                srcset=""
              />
              <motion.div
                initial={{ opacity: 0, x: -90, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <BlueBox src="/square_2.gif" />
              </motion.div>
              <motion.img
                initial={{ opacity: 0, x: -100, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                src="/go.svg"
                alt=""
                srcset=""
              />
              <motion.div
                initial={{ opacity: 0, x: -120, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
              >
                <BlueBox src="square_3.gif" />
              </motion.div>
              <motion.img
                initial={{ opacity: 0, x: -140, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 1.2 }}
                src="/go.svg"
                alt=""
                srcset=""
              />
              <motion.div
                initial={{ opacity: 0, x: -160, rotate: 0 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{ duration: 1.4 }}
              >
                <BlueBox src="square_4.gif" />
              </motion.div>
            </div>

            <div className="flex relative flex-col lg:hidden justify-between items-center">
              <div className="flex gap-x-1 mb-[10px]">
                <BlueBox src={null} mobile={true} />
                <img src="/go.svg" alt="" srcset="" />
                <BlueBox src="/square_2.gif" mobile={true} />
              </div>
              <div className="absolute rotate-90 bottom-[156px] right-[40px] ">
                <img src="/go.svg" alt="" srcset="" />
              </div>
              <div className="flex mt-[78px] gap-x-1">
                <BlueBox src="/square_4.gif" mobile={true} />
                <img className="rotate-180 " src="/go.svg" alt="" srcset="" />
                <BlueBox src="/square_3.gif" mobile={true} />
              </div>
            </div>
            {/* <img className="" src="/cycle.png" alt="" srcset="" /> */}
            {/* <img className="object-contain hidden md:block" src="/sl.png" alt="" srcset="" /> */}
          </div>
        </div>
      </div>

      <div className="bg-[#F6F7FB]">
        <div className="pb-[80px]">
          <motion.p
            initial={{ opacity: 0, x: -50, rotate: 0 }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="font-[400] px-2 pb-[40px] pt-[80px] text-[28px] lg:text-[32px] text-center"
            style={{ color: "rgba(2, 7, 26, 0.80)" }}
          >
            {" "}
            How to
            <span className="font-[600]"> Evolve</span> your{" "}
            <span className="font-[600]"> NFTs</span>
          </motion.p>
          <div className="flex flex-wrap justify-center items-end space-y-[30px]">
            <OptionCard0 />
            <OptionCard1 />
            <OptionCard2 />
          </div>
        </div>
      </div>

      {/* <div className="pb-[24px]"> */}
      {/* <Makemoney /> */}
      {/* <Plus /> */}
      {/* </div> */}
      <div className="">
        <Questions />
      </div>
    </div>
  );
}

export default Benefits;
