import React, { useCallback, useState } from "react";
import EditButton from "./EditButton";
import axios from "axios";
import EditTwitter from "./EditTwitter";
import Tick from "./Tick";

const userData = [
  // {
  //   no: "01",
  //   twitterUsername: "@JamesCiesluk",
  //   otherusers: ["JamesCiesluk", "JamesCiesluk", "JamesCiesluk"],
  //   wallet: "wdfdr124563475575",
  //   transactionid: "wdfdr124563475575",
  //   timesOfPurchased: "01",
  //   paymentMethod: "Meta mask",
  //   nftHold: "01",
  // },
  // {
  //   no: "02",
  //   twitterUsername: "@SarahSmith",
  //   otherusers: ["SarahSmith", "SarahSmith", "SarahSmith"],
  //   wallet: "jdfgh589034sjdgh",
  //   transactionid: "jdfgh589034sjdgh",
  //   timesOfPurchased: "05",
  //   paymentMethod: "PayPal",
  //   nftHold: "02",
  // },

  // {
  //   no: "03",
  //   twitterUsername: "@JohnDoe",
  //   otherusers: ["JohnDoe", "JohnDoe", "JohnDoe"],
  //   wallet: "hfgd894jgdv398j",
  //   transactionid: "hfgd894jgdv398j",
  //   timesOfPurchased: "10",
  //   paymentMethod: "Credit Card",
  //   nftHold: "03",
  // },

  {
    index: "07",
    twitter: "@RobertLee",
    walletAddress: "lkjhg8742bvnm",
    amount: "67",
    trans_id: "lkjhg8742bvnm",
    created_at: "04",
  },
  {
    index: "08",
    twitter: "@RobertLee",
    walletAddress: "lkjhg8742bvnm",
    amount: "67",
    trans_id: "lkjhg8902bvnm",
    created_at: "04",
  },
];

const TableData = ({
  keys = [],
  data = [],
  setTwitterUpdate,
  twitterUpdate,
  transactionData,
  fetchTransactions
}) => {

  const [editedUsername, setEditedUsername] = useState("");
  const [editedStatus, setEditedStatus] = useState("");
  const [editedTransId, setEditedTransId] = useState(null);
  const [statusValue, setStatusValue] = useState("");
  
  const handleEdit = useCallback((transId) => {
    setEditedTransId(transId);
  }, []);


  const handleSave = useCallback(async (id) => {
    // API call logic
    const res = await axios
    .patch(`${process.env.REACT_APP_PUBLIC_URL}/transactions/edit-twitter`, {
      id: id,
      twitter: editedUsername,
    })
    .then((res) => {
      const data = res?.data;
    });
    setEditedTransId(null);
    setEditedUsername("");
    setTwitterUpdate(!twitterUpdate);
  }, [twitterUpdate]);

  const handleEditStatus = useCallback(async (id) => {
    // API call logic
    const res = await axios
    .patch(`${process.env.REACT_APP_PUBLIC_URL}/transactions/edit-status`, {
      id: id,
      status: statusValue,
    })
    .then((res) => {
      const data = res?.data;
    });

    setEditedTransId(null);
    setTwitterUpdate(!twitterUpdate);
  }, [twitterUpdate]);

  const handleSelectChange = useCallback((e, id) => {
    const selectedValue = e.target.value;
    setStatusValue(selectedValue);
    setEditedStatus((prevStatus) => ({ ...prevStatus, [id]: selectedValue }));
  }, []);

  const handleBoostStatus = useCallback(async (id, boostStatus, walletAddress) => {
    // API call logic
    const res = await axios
    .patch(`${process.env.REACT_APP_PUBLIC_URL}/user/edit-boostdate`, {
     
      walletAddress: walletAddress,
      boostStatus: boostStatus,
    })
    .then((res) => {
      const data = res?.status;
      
    });
    fetchTransactions();
  }, []);


  
  return (
    <div className=" rounded-lg lg:overflow-x-scroll lg:w-[1150px]  2xl:w-[1450px] ">
      <table className="   text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          style={{ background: "rgba(98, 126, 234, 0.08)" }}
          className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            {keys.map((key) => (
              <th scope="col" className="px-6 py-3">
                {key.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-[800px]">
          {data.map((ele, index) =>{ 
           
            const userDetails = ele?.user
           
              const currentDate = new Date().getTime();
              const math = Math.abs(
                Math.ceil(
                  (new Date(userDetails?.boostDate).getTime() - currentDate) /
                    (1000 * 60 * 60 * 24)
                )
              )
              
            const datecheck = userDetails?.boostDate        
                ? Math.abs(
                    Math.ceil(
                      (new Date(userDetails?.boostDate).getTime() - currentDate) /
                        (1000 * 60 * 60 * 24)
                    )
                  ) < 29
                : false;
                
           return (
            
            <tr
              key={index}
              className={`border-b border-[#EBECED] dark:bg-gray-800 dark:border-gray-700 ${
                ele.status === "pending"
                  ? "bg-yellow-100"
                  : ele.status === "failed"
                  ? "bg-red-200"
                  : "bg-green-200"
              }`}
            >
              {keys.map((_, index) => (
                <td
                  key={index}
                  scope="col"
                  className="px-6 py-3 font-poppins font-normal "
                >
                  <div className="w-[100px] bg-red-400">
                  {_.key === "walletAddress" ? "":""}
                  </div>
                  {_.key === "twitter" ? (
                    <div>
                      {editedTransId === ele.trans_id ? (
                        <div className="flex">
                          <EditTwitter
                            value={editedUsername}
                            onChange={(event) => {
                              setEditedUsername(event.target.value);
                            }}
                            onSave={() => handleSave(ele._id)}
                          />
                        </div>
                      ) : (
                        <div>
                          <span>{ele.twitter}</span>
                          <button
                            className="mx-2"
                            onClick={() => handleEdit(ele.trans_id)}
                          >
                            <EditButton />
                          </button>
                        </div>
                      )}
                    </div>
                  ) : _.key === "status" ? (
                    <div className="flex">
                      <select name=""  value={editedStatus[ele.index] || ele[_.key]}
                        onChange={(e) => handleSelectChange(e, ele.index)}
                      
                      id="">
                        <option value="failed">failed</option>
                        <option value="pending">pending</option>
                        <option value="confirmed">confirmed</option>
                      </select>
                      <button
                        className="mx-2  bg-black px-2  rounded-md text-white"
                        onClick={() => handleEditStatus(ele._id)}
                      ><Tick /></button>
                      <button 
                      className={`text-white p-1 font-semibold rounded-md ${datecheck === true? "bg-green-600":"bg-red-600"}`}
                      onClick={() => handleBoostStatus(ele._id,datecheck,ele.walletAddress)}
                      >
                        Boost</button>
                    </div>
                  ) : (
                    <span>{ele[_.key]}</span>
                  )}
                </td>
              ))}
            </tr>
          )})}
        </tbody>
      </table>
    </div>
  );
};

export default TableData;
