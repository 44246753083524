import React from "react";

function CheckEmail() {
  return (
    <div className="flex flex-col px-9 h-screen md:w-[602px] mx-auto">
      <div className="my-4 mt-[24px] mb-[138px]">
        <p className="text-white text-[32px] flex items-center justify-center font-[700]">
          627EEA
        </p>
      </div>
      <div className="rounded-2xl flex justify-center items-center flex-col gap-y-[46px] bg-white shadow-xl md:p-[39px] p-6">
      <img src="/email.svg" alt="email" srcset="" />
      <p className="text-[#02071A] opacity-80 text-center flex justify-center items-center text-[30px] font-[500]">We have sent you a link. Check your email.</p>
      </div>
    </div>
  );
}

export default CheckEmail;
