import React from "react";

const EditButton = () => {
  return (
    <div>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5836 3.00002L4.74186 10.2417C4.48353 10.5167 4.23353 11.0584 4.18353 11.4334L3.87519 14.1334C3.76686 15.1084 4.46686 15.775 5.43353 15.6084L8.11686 15.15C8.49186 15.0834 9.01689 14.8084 9.27522 14.525L16.1169 7.28335C17.3002 6.03335 17.8336 4.60835 15.9919 2.86668C14.1586 1.14168 12.7669 1.75002 11.5836 3.00002Z"
          stroke="#02071A"
          stroke-width="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.4413 4.20837C10.7996 6.50837 12.6663 8.26671 14.983 8.5"
          stroke="#02071A"
          stroke-width="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.03326 18.3334H18.0333"
          stroke="#02071A"
          stroke-width="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditButton;
