import { getNFT } from "api/nft";
import { useState, createContext, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

const NFTContext = createContext(null);

export function useNFT() {
  return useContext(NFTContext);
}

export default function NFTProvider({ children }) {
  // const { state } = useLocation();
  const navigate = useNavigate();
  const { address } = useAccount();
  const [nftCollection, setNFTCollection] = useState([]);
  useEffect(() => {
    // if (state) {
    //   setNFTCollection(state.nfts);
    //   return;
    // }
    if (address) {
      getNFT(address).then((data) => {
        if (data.statusCode === 200) {
          if (data.data.length === 0) {
            navigate("/do-not-have-nft");
          }
          setNFTCollection(data.data);
        } else {
          navigate("/do-not-have-nft");
        }
      });
    }
  }, [address]);

  const clearNFTCollection = () => {
    setNFTCollection([]);
  };

  const updateNFTCollection = (newNFT) => {
    setNFTCollection(
      nftCollection.map((n) => (n.id === newNFT.id ? newNFT : n))
    );
  };

  return (
    <NFTContext.Provider
      value={{ nftCollection, clearNFTCollection, updateNFTCollection }}
    >
      {children}
    </NFTContext.Provider>
  );
}
