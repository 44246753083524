import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "sections/Header";
import LeftMenu from "sections/LeftMenu";
import NFTsection from "sections/NFTsection";
import { useAccount } from "wagmi";
import { Animation } from "common";
import NFTProvider from "context/nft";
import UserProvider from "context/user";
import PageLoader from "common/PageLoader";
import { motion } from "framer-motion";
import PaymentBanner from "sections/LeftMenu/components/Popups/PaymentBanner";

export default function Dashboard() {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const [showLoading, setShowLoading] = useState(true);

  const [paid, setpaid] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, []);

  const [checked, setChecked] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );

  useEffect(() => {
    if (!isConnected) {
      navigate("/connect");
    }
  }, [isConnected]);

  return (
    <>
      <UserProvider>
        <NFTProvider>
          <PaymentBanner paid={paid} />
          {showLoading ? (
            <PageLoader />
          ) : (
            <motion.div
              className="z-30 overflow-x-hidden"
              initial={{ opacity: 0, rotate: 0 }}
              whileInView={{
                opacity: 1,
              }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
            >
              <Header checked={checked} setChecked={setChecked} />
              <div className="pt-8 pb-9 md:py-9 z-30 gap-x-7 pl-3 md:pl-11  md:pr-5 maindiv overflow-x-hidden w-[100%] justify-center md:items-start items-center flex flex-col md:flex-row align-start gap-y-10 max-w-[1700px] mx-auto">
                <div className="flex-1 flex justify-center -ml-5 md:ml-0 w-full md:w-fit md:max-w-[303px] pl-2 md:pl-0 z-[5]">
                  <LeftMenu setpaid={setpaid} />
                </div>

                <div className="flex-1 md:flex-[3_3_0] ml-[-14px] overflow-x-hidden z-[5]">
                  <NFTsection />
                </div>
                <Animation
                  className={`${checked ? "!bg-primary" : "!bg-[#ffffff33]"}`}
                />
              </div>
            </motion.div>
          )}
        </NFTProvider>
      </UserProvider>
    </>
  );
}
