
import React from "react";

const EditTwitter = ({ value, onChange, onSave }) => {
  return (
    <div className="flex">
      <input
        className="p-2 rounded-lg"
        type="text"
        value={value}
        onChange={onChange}
      />
      <button className="mx-2 font-semibold" onClick={onSave}>
        Save
      </button>
    </div>
  );
};

export default EditTwitter;
