import { twMerge } from "tailwind-merge";

export default function Logo({ className = "", ...rest }) {
  return (
    <>
      <p
        className={twMerge(
          "text-white text-3xl font-bold",
          className
        )}
        {...rest}
      >
        627EEA
      </p>
    </>
  );
}
