import React, { useEffect, useState } from 'react';
import { useUser } from 'context/user';


import BlackCat from '../assets/images/animal/black cat.png';
import BlackChicken from '../assets/images/animal/black chicken.png';
import BlackCroc from '../assets/images/animal/black croc.png';
import BlackDragon from '../assets/images/animal/black dragon.png';
import BlackShark from '../assets/images/animal/black shark.png';

import { Loader } from 'common';

const defaultImages = {
  cat: BlackCat,
  dragon: BlackDragon,
  shark: BlackShark,
  croc: BlackCroc,
  chicken: BlackChicken,
};

const AnimalImage = ({ breed, alt, defaultImage }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileHovered, setIsMobileHovered] = useState(false);

  return (
    <div
      className='lg:w-[300px] lg:h-[300px] w-[120px] h-[180px] py-3 mx-3 lg:mx-0 relative cursor-pointer'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick= {() => setIsMobileHovered(!isMobileHovered)}
    >
      <img src={breed?.imageUrl || defaultImage} alt={alt} />

      {isHovered && (
        <div className='lg:absolute lg:block sm:hidden tooltip-text text-xs -top-20 left-5 right-35 border-[1px] border-black bg-white text-black  text-center p-[2px] w-fit bg-opacity-75 '>
         {breed?.breedText }
        </div>
      )}
       {isMobileHovered && (
        <div onClick= {() => setIsMobileHovered(!isMobileHovered)} className='lg:hidden tooltip-text absolute text-xs -top-20 left-5 right-35 border-[1px] border-black bg-white text-black  text-center p-[2px] w-fit bg-opacity-75 '>
         {breed?.breedText }
        </div>
      )}
    </div>
  );
};

const Breed = ({nftImages}) => {
  
  // const {user:userDetails} = useUser();
  // useEffect(() =>{
  //   setAddress(userDetails?.walletAddress)
  // })
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    const myFunction = () => {
      setLoading(true)
    };
    const intervalId = setInterval(myFunction, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div>
       { loading === false   ? <div className='h-[200px] w-[400px] flex items-center justify-center'><Loader /></div> :
       <div className='flex flex-wrap justify-center max-w-[900px]'>
       {Object.entries(nftImages).map(([animal, images], index) => (
         <React.Fragment key={index}>
           {images.length > 0 ? (
             images.map((item, subIndex) => (
               <AnimalImage
                 key={subIndex}
                 breed={item}
                 alt={animal}
                 defaultImage={defaultImages[animal]}
               />
             ))
           ) : (
             <AnimalImage
               key={index}
               breed={null}
               alt={animal}
               defaultImage={defaultImages[animal]}
             />
           )}
         </React.Fragment>
       ))}
     </div>
       }
    </div>
  );
};

export default Breed;
