export default function Arrow() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
      >
        <path
          d="M0.167969 8.32031C0.167969 8.76329 0.497148 9.12938 0.924236 9.18732L1.04297 9.19531L18.543 9.19531C19.0262 9.19531 19.418 8.80356 19.418 8.32031C19.418 7.87733 19.0888 7.51124 18.6617 7.4533L18.543 7.44531L1.04297 7.44531C0.55972 7.44531 0.167969 7.83706 0.167969 8.32031Z"
          fill="white"
        />
        <path
          d="M10.867 14.7291C10.5245 15.0701 10.5234 15.6241 10.8643 15.9665C11.1743 16.2779 11.6603 16.3071 12.0034 16.0537L12.1018 15.9692L19.1601 8.94121C19.4723 8.63031 19.5007 8.14251 19.2453 7.79942L19.1601 7.70116L12.1018 0.671994C11.7594 0.330994 11.2054 0.332141 10.8644 0.674556C10.5544 0.985843 10.5271 1.47201 10.782 1.81403L10.8669 1.91199L17.3023 8.32151L10.867 14.7291Z"
          fill="white"
        />
      </svg>
    </>
  );
}
