import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Questions() {
  const [questions, setQuestions] = useState([
    {
      question: "What is 627EEA?",
      answer:
        "627EEA is an organization created to establish color consensus. Each square is a representation of membership. Metadata can evolve to reveal special changes in your digital identity.        ",
      pm: false,
    },
    {
      question: "Is it blue or is it purple?      ",
      answer:
        "Great question. To some, it is blue. To others, it is purple. Does it matter? No. Whatever color it feels to you, then it is. If it gets people talking, then we’re winning.        ",
      pm: false,
    },
    {
      question: "What are the evolutions?      ",
      answer:
        "Every square has the capability to evolve into something special. There are many different evolutions to go through. You can evolve your squares in a few different ways.        ",
      pm: false,
    },
    {
      question: "How can I evolve my square?      ",
      answer:
        "There are three methods to evolving, this depends on how many squares you hold and stake. For anyone who stakes more than 5 squares at a time, you can evolve for free. If you own less than 5 squares you have two other options. You can stake your square(s) for 30 days then evolve them OR pay an evolution fee to instantly get access to evolution rights to your square(s).        ",
      pm: false,
    },
    {
      question: "Is the evolution fee a one-time payment or monthly?",
      answer: "It is a monthly fee for anyone who stakes less than 5 squares. You are not required to pay this fee if you are willing to stake your squares for 30 days. This will unlock the evolution. The only way to avoid this fee is by staking more than 5 squares or waiting 30 days to evolve.      ",
      pm: false,
    },
  ]);

  const handleTogglePM = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].pm = !updatedQuestions[index].pm;
    setQuestions(updatedQuestions);
  };
  return (
    <div id="faq" className="bg-[#F6F7FB] px-[20px] pt-[20px]">
      <p
        className="text-[24px] text-center"
        style={{ color: "rgba(2, 7, 26, 0.80)" }}
      >
        <span className="font-[500] pt-[80px] md:text-[32px] text-center capitalize">Frequently asked questions</span>
      </p>
      <p className="text-center text-[#02071A]  text-[13px]  md:text-[16px] font-[400] opacity-70 pt-[16px]">
      Answering common questions regarding 627EEA.
      </p>

      {/* toggle */}
      <motion.div
        initial={{ opacity: 0, x: 50, rotate: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className="flex flex-col justify-center items-center mt-[40px] space-y-[16px] md:mx-[46px]"
      >
        {questions?.map((question, index) => (
          <div
            onClick={() => handleTogglePM(index)}
            key={index}
            style={{ boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.05)" }}
            className={`flex w-full transition-all transform duration-[350ms] ease-in-out md:w-[60%] flex-col justify-between border-[#627EEA] border-[2.3px] rounded-[24px] p-[16px] ${
              question.pm ? "bg-[#F6F7FB]" : ""
            }`}
          >
            <div className="flex items-center justify-between">
              <p
                className={`text-[16px] w-fit flex-3 font-[500] ${
                  question.pm ? "text-[#02071A]" : "text-[#02071A]"
                }`}
              >
                {question.question}
              </p>
              <div className="w-[14px]">
                <img className="" src={question.pm ? "/minus.svg" : "/plus.svg"} alt="" />
              </div>
            </div>
            {question.pm && (
              <div
                className={`mt-3  ${
                  question.pm ? "max-h-[500px] overflow-hidden" : "max-h-0"
                }`}
              >
                <p
                  style={{ whiteSpace: "pre-line" }}
                  className="text-[#02071A] opacity-90"
                >
                  {question.answer}
                </p>
              </div>
            )}
          </div>
        ))}
      </motion.div>
      <div className="n1">
        <Link to="/FAQS">
          <button
            style={{ boxShadow: "4px 4px 24px 0px rgba(98, 126, 234, 0.40)" }}
            className="bg-[#627EEA] flex justify-center items-center text-[16px] font-[700] text-[#FFF] rounded-[12px] mt-[48px] mb-[80px] px-[46px] py-[14px]"
          >
            Go to FAQS{" "}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Questions;
