import { Button } from "common";
import Popup from "../components/Popup.jsx";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { motion } from "framer-motion";
import { Arrow, Twitter, TwitterTick } from "icons";
import { useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import Discord from "components/Discord";
import OpenSea from "components/OpenSea";
import { useSocket } from "context/socket.js";
import { useNFT } from "context/nft";
import { useUser } from "context/user";
import { twJoin } from "tailwind-merge";
import AskForTwtter from "./LeftMenu/components/Popups/AskForTwtter.jsx";
import { CurrDate } from "./LeftMenu/utils/index.js";
import Boost from "./LeftMenu/components/Popups/Boost.jsx";
import Payment from "./LeftMenu/components/Popups/Payment.jsx";
import Termss from "./LeftMenu/components/Popups/Terms.jsx";
import Congratulations from "./LeftMenu/components/Popups/Congratulations.jsx";
import Failed from "./LeftMenu/components/Popups/Failed.jsx";
import LoadingPopup from "./LeftMenu/components/Popups/LoadingPopup.jsx";
import Pending from "./LeftMenu/components/Popups/Pending.jsx";
import PaymentMobile from "./LeftMenu/components/Popups/PaymentMobile.jsx";
import WalletDisconnect from "./LeftMenu/components/Popups/WalletDisconnect.jsx";
import SmallCircle from "icons/SmallCircle.jsx";
import WalletSmallCircleIcon from "icons/WalletSmallCircleIcon.jsx";

export default function LeftMenu({setpaid}) {
  const { nftCollection, clearNFTCollection } = useNFT();
  const { user: userDetails, refetch: refetchUser, clearUser } = useUser();
  const { socket } = useSocket();
  const [isWalletHovered, setWalletHovered] = useState(false);
  const [twitterName, setTwitterName] = useState("");
  const [username, setUsername] = useState(null);
  const { address } = useAccount();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedTextPopup, setSelectedTextPopup] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // month state to handle month tab functionality
  const [selectedMonth, setSelectedMonth] = useState("");
  // console.log("🚀 ~ file: LeftMenu.jsx:44 ~ LeftMenu ~ selectedMonth:", selectedMonth)
  // const [monthButton, setmonthButton] = useState("");
  const [date, setDate] = useState(false);
  // const { disconnect } = useDisconnect({
  //   onSuccess(data) {
  //     clearNFTCollection();
  //     clearUser();
  //   },
  // });

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // }
  //get user name from local storage
  useEffect(() => {
    const UserName = localStorage.getItem("UserName");
    const fullname = JSON.parse(UserName);
    setUsername(fullname);
  }, []);
  // const handleAddUser = async (username) => {
  //   if (username.trim() !== "" && username.startsWith("@")) {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_PUBLIC_URL}/user/verify-twitter-id`,
  //         {
  //           username,
  //         }
  //       );
  //       toast.success("User added!", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       // setUsers([...users, username]);
  //       // Handle the response
  //     } catch (error) {
  //       console.error(error);
  //       toast.error("This user not linkend with twitter", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });

  //       return;
  //     }
  //   }
  // };
//build socket connection
  useEffect(() => {
    if (socket == null) return;
    socket.emit("join_room", address);
    socket.on("room_joined", (data) => {});
    socket.on("transaction_update", (data) => {
      if (data.type === "boost") {
        if (data.status === "success") {
          setSelectedTextPopup("congratulation");
          setPopupOpen(true);
          refetchUser();
        }
        if (data.status === "failed") {
          setSelectedTextPopup("failed");
          setPopupOpen(true);
        }
      }
    });
    CurrDate(setDate)
    return () => {
      socket.off("room_joined");
      socket.off("transaction_update");
    };
   
  }, [socket]);
 //to handle checkout
  function checkout(skipTerms = false) {
    if (isChecked === false && !skipTerms) {
      toast.error("Please agree all terms and conditions!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (window.innerWidth < 768) {
      setSelectedTextPopup("paymentFromMobile");
      setPopupOpen(true);
      return;
    }

    setLoading(true);
    setSelectedTextPopup("loading");

    try {
      var windowReference = window.open("", "_blank");
    axios
        .post(`${process.env.REACT_APP_PUBLIC_URL}/checkout`, {
          amount: 60,
          currency: "usd",
          userId: address,
          type: "boost",
          twitterName: localStorage.getItem("UserName")
            ? JSON.parse(localStorage.getItem("UserName")).screenName
            : userDetails?.twitter ?userDetails?.twitter : twitterName,
          month: selectedMonth,
        })
        .then((res) => res.data)
        .then(({ charge }) => {
          if (!windowReference) {
            alert("Please allow popups for this website");
            return;
          }
          if (charge) {
            windowReference.location = charge.hosted_url;
            windowReference.focus();
          } else {
            windowReference.close();
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    
    } catch (error) {
      setLoading(false);

      console.error(error);
      // Handle error
    }
  }
  // handle month
  // const handleMonth = (e, id) => {
  //   setSelectedMonth(e);
  //   setbuttonid(id);
  // };
  //////////////////////////////////////////////


  //popus data in dashboard
  let data = {
    walletdisconnect: (
      <WalletDisconnect setPopupOpen={setPopupOpen}  />
    ),
    askForTwitter: (
     <AskForTwtter setSelectedMonth={setSelectedMonth} loading={loading}  checkout={checkout} userDetails={userDetails} setSelectedTextPopup={setSelectedTextPopup}     isChecked ={isChecked} setIsChecked={setIsChecked}  setTwitterName={setTwitterName} twitterName={twitterName}  setpaid={setpaid} setDate={setDate} />
    ),
    boost:(
      <Boost date={date} setSelectedTextPopup={setSelectedTextPopup} />
    ),
    payment: (
      <Payment setSelectedTextPopup={setSelectedTextPopup} checkout={checkout} loading={loading} userDetails ={userDetails} username={username} isChecked={isChecked} setIsChecked={setIsChecked} />
    ),
    terms: (
      <Termss setSelectedTextPopup={setSelectedTextPopup}  setIsChecked={setIsChecked} />
    ),
    congratulation: (
      <Congratulations />
    ),
    failed: (
      <Failed setPopupOpen={setPopupOpen} checkout={checkout} />
    ),
    paymentFromMobile: (
      <PaymentMobile />
    ),
    pending: (
      <Pending />
    ),
    loading: (
      <LoadingPopup loading={loading} />
    ),
  };
  //please filter to those nfts who is staked
  const stakednfts = useMemo(
    () =>
      nftCollection?.filter(
        (nft) => nft.stakeStatus == true || nft.evolveCount > 0
      ) || [],
    [nftCollection]
  );
  const isboostActive = useMemo(() => {
    const currentDate = new Date().getTime();
    return userDetails?.boostDate
      ? Math.abs(
          Math.ceil(
            (new Date(userDetails?.boostDate).getTime() - currentDate) /
              (1000 * 60 * 60 * 24)
          )
        ) < 29
      : false;
  }, [userDetails]);

  const isEvolveActive = useMemo(() => {
    const currentDate = new Date().getTime();
    return userDetails?.evolveDate
      ? Math.abs(
          Math.ceil(
            (new Date(userDetails?.evolveDate).getTime() - currentDate) /
              (1000 * 60 * 60 * 24)
          )
        ) < 31
      : false;
  }, [userDetails]);
  return (
    <>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        children={data[selectedTextPopup]}
        showCloseIcon={
          !(selectedTextPopup === "loading" || selectedTextPopup === "pending")
        }
      />
     
      <div className="h-fit md:h-[625px] px-5 pt-4 md:pt-8 pb-4 md:pb-5 bg-white rounded-2xl max-w-[330px] md:max-w-xs w-[330px] md:w-fit md:sticky md:top-[120px]">
        <div className="flex flex-col gap-6">
          <div className="w-full flex items-center justify-center">
            <div
              className={twJoin(
                "flex flex-col items-center justify-start",
                username ? "gap-2" : "gap-4"
              )}
            >
              <div className="border-4 border-primary w-[97px] h-[97px] rounded-xl">
                {username ? (
                  <img
                    className="w-full h-full object-cover border-4 bg-primary rounded-[7px]  border-primary"
                    src={username?.providerUserInfo?.[0]?.photoUrl}
                    alt=""
                    srcset=""
                  />
                ) : (
                  <img
                    src={
                      userDetails?.profileImageUrl ??
                      require("assets/images/avatar.png")
                    }
                    alt="avatar"
                    className="w-full h-full border-4 bg-primary rounded-[7px]  border-primary"
                  />
                )}
              </div>
              <div className="w-full">
                <div className="flex items-center gap-[6px] justify-center">
                  <p className="text-lg font-medium text-theme-gray">
                    {!username && userDetails?.username}
                    {username && username.displayName}
                  </p>
                  {username && <TwitterTick />}
                </div>
                {username && (
                  <p className="text-sm text-theme-black text-opacity-70 text-center">
                    @{username?.screenName}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-x-4 gap-y-4 justify-center md:justify-between items-center">
            <button
              style={{ backgroundColor: "rgba(17, 216, 133, 0.08)" }}
              className="flex w-[160px] p-[12px] text-[15px] rounded-[8px] text-[#11D885] justify-center gap-x-2 font-[600] items-center"
              onClick={() => {
                setSelectedTextPopup("walletdisconnect");
                setPopupOpen(true);
              }}
              onMouseLeave={() => setWalletHovered(false)}
              onMouseEnter={() => setWalletHovered(true)}
              onTouchStart={() => setWalletHovered(true)}
              onTouchEnd={() => setWalletHovered(false)}
            >
              <WalletSmallCircleIcon />
              {isWalletHovered ? (
                <p className="whitespace-nowrap text-[14px] ">
                  Disconnect Wallet
                </p>
              ) : (
                <p className="whitespace-nowrap text-[14px] ">
                  Wallet Connected
                </p>
              )}
            </button>
            <motion.button
              onClick={() => {
                setSelectedTextPopup("boost");
                setPopupOpen(true);
              }}
              // disabled={isboostActive ? true : stakednfts?.length === 0}
              whileHover={{
                scale: 1,
                transition: { duration: 1 },
              }}
              whileTap={{ opacity: 0.3 }}
              style={{
                backgroundColor: isboostActive
                  ? "rgba(17, 216, 133, 0.08)"
                  : "rgba(239, 40, 76, 0.18)",
              }}
              className={`flex p-[12px] group text-[15px] rounded-[8px]  justify-center gap-x-2 font-[600] items-center ${
                isboostActive ? "text-[#11D885]" : "text-[#EF284C]"
              }`}
            >
             <SmallCircle isboostActive={isboostActive} />
              <p className="text-[14px] px-1 ">
                {isboostActive ? "Boosted" : "Boost"}
              </p>
              {isboostActive
                ? ""
                : stakednfts?.length == 0 && (
                    <div className="hidden group-hover:flex gap-x-3 w-[280px] right-3 top-[100px] absolute px-[10px] bg-[#02071A] py-2 opacity-80 rounded-[8px]">
                      <img src="./tooltip.svg" alt="" srcset="" />
                      <p className="text-[11px] text-start text-[#FFF]">
                        You haven't boosted your account. <br /> Stake at least
                        1 square to unlock this feature.{" "}
                      </p>
                    </div>
                  )}
            </motion.button>
          </div>
          <div className="flex justify-between gap-y-[15px] items-center flex-col">
            <div className="flex justify-between items-center w-full px-1">
              <p className="text-[#02071A] text-[14px] font-[300]">
                Boost Status:
              </p>
              <p className="text-[#02071A] text-[14px] font-[400]">
                {isboostActive ? "Active" : "Inactive"}
              </p>
            </div>
            <div className="flex justify-between items-center w-full px-1">
              <p className="text-[#02071A] text-[14px] font-[300]">
                Last Purchased:
              </p>
              <p className="text-[#02071A] text-[14px] font-[400]">
                {userDetails?.lastPerchasDate
                  ? new Date(userDetails?.lastPerchasDate).toLocaleDateString()
                  : "-"}
              </p>
            </div>
            <div className="flex justify-between items-center w-full px-1">
              <p className="text-[#02071A] text-[14px] font-[300]">
                Join Date:
              </p>
              <p className="text-[#02071A] text-[14px] font-[400]">
                {userDetails?.joinDate
                  ? new Date(userDetails?.joinDate).toLocaleDateString()
                  : "-"}
              </p>
            </div>
            <div className="flex justify-between items-center w-full px-1">
              <p className="text-[#02071A] text-[14px] font-[300]">
                Evolve Rights:
              </p>
              <p className="text-[#02071A] text-[14px] font-[400]">
                {userDetails?.evolveDate
                  ? isEvolveActive
                    ? "Expires -"
                    : "-"
                  : "-"}
                &nbsp;
                {userDetails?.evolveDate
                  ? new Date(
                      new Date(userDetails?.evolveDate).getTime() +
                        30 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString()
                  : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-row md:flex-col gap-1.5">
            <a
              href="https://discord.com/invite/jncxlabs"
              target="_blank"
              className="flex-1 "
              rel="noopener noreferrer"
            >
              <Button className="w-full flex justify-center md:justify-start rounded-[12px] font-[400] text-[18px] px-2 border text-[#02071A] ">
                <div className="md:pl-[12px] md:pr-[52px]">
                  <Discord />
                </div>
                <span className="hidden md:contents">Discord</span>
              </Button>
            </a>
            <a
              href="https://opensea.io/collection/627eea"
              target="_blank"
              className="flex-1 "
              rel="noopener noreferrer"
            >
              <Button className="w-full flex justify-center md:justify-start  rounded-[12px] font-[400] text-[18px] px-2 border text-[#02071A] ">
                <div className="md:pl-[12px] md:pr-[48px]">
                  <OpenSea />
                </div>
                <span className="hidden md:contents">OpenSea</span>
              </Button>
            </a>
            <></>
            <a
              href="https://twitter.com/627EEA"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-1 "
            >
              <Button className="w-full flex justify-center md:justify-start  rounded-[12px] font-[400] text-[18px] px-2 border text-[#02071A] ">
                <div className="md:pl-[12px] md:pr-[40px]">
                  <Twitter />
                </div>
                <span className="hidden md:contents">Community</span>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
