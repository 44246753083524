import React from 'react'

const Congratulations = () => {
  return (
    <div>
        <div className="px-8 md:px-[78px] overflow-y-hidden  max-w-lg">
        <div className="flex justify-center items-center ">
          <img
            className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
            src="./success.svg"
            alt=""
            srcset=""
          />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center">
          <p className="text-[#02071A] text-[28px] font-[600] pt-10 md:pt-[56px] pb-[40px]">
            Congratulations!
          </p>
          <p className="text-[#02071A] text-[15px] font-[500] text-center">
            Your account is now Boosted.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Congratulations