import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useCountUp } from "use-count-up";
import { useEffect } from "react";
import axios from "axios";

export default function Hero() {
  // const [start, setStart] = useState(92);
  // const [end, setEnd] = useState(10000);
  // const [end1, setEnd1] = useState(10000);

  // const [duration, setDuration] = useState(6);
  // const [decimalPlaces, setDdecimalPlaces] = useState(0);
  // const [easing, setEasing] = useState("easeOutCubic");
  // const [thousandsSeparator, setThousandsSeparator] = useState("");
  // const [decimalSeparator, setDecimalSeparator] = useState("");

  // const [stats, setstats] = useState(null);
  // const [owner, setowner] = useState(null);
  // const [error, setError] = useState(null);
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const headers = {
  //         Authorization: "Bearer 49b8c9189e894982b79bd63ef83cc12c", // Replace with your actual API key
  //         "X-API-KEY": "49b8c9189e894982b79bd63ef83cc12c", // Replace with your actual API key
  //       };

  //       const response = await axios.get(
  //         "https://api.opensea.io/api/v1/collection/627eea",
  //         { headers }
  //       );

  //       setEnd(response.data.collection.stats.count);
  //       setEnd1(response.data.collection.stats.num_owners);
  //     } catch (error) {
  //       setError(error);
  //     }
  //   }

  //   fetchData();
  // }, []);

  // const { value, reset } = useCountUp({
  //   isCounting: true,
  //   start,
  //   end,
  //   duration,
  //   easing,
  //   decimalPlaces,
  //   thousandsSeparator,
  //   decimalSeparator,
  // });
  // const data = useCountUp({
  //   isCounting: true,
  //   start: 100,
  //   end: end1,
  //   duration: 6,
  //   easing: "easeOutCubic",
  //   decimalPlaces: 0,
  //   thousandsSeparator: "",
  //   decimalSeparator: "",
  // });

  return (
    <div className="bg-[#F6F7FB] w-full" id="home">
      <div className="max-w-7xl w-full pt-32 sm:py-20 pb-[11rem] lg:pt-[175px] lg:pb-40 mx-auto">
        <div className="flex flex-col gap-12 items-center">
          <>
            <motion.p
              initial={{ opacity: 0, x: -50, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="text-center text-slate-950 text-opacity-80 text-[36px] lg:text-[80px] font-normal leading-[57px] lg:leading-[96px]"
            >
              More than just a <span className="font-extrabold">square.</span>
            </motion.p>
          </>

          {/* <motion.div
            animate={{
              scale: [1, 0, 1.5, 0.6, 1],
              // rotate: [0, 0, 90, 180, 0],
              // borderRadius: ["0%", "0%", "50%", "50%", "0%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              // times: [0, 0.2, 0.5, 0.8, 1],
            }}
            className="w-[280px] lg:w-[350px] h-[280px] lg:h-[350px] bg-primary rounded-2xl shadow"
          /> */}
          <div>
            <div
            >
              <img src="./main.gif" alt="" srcset="" />
            </div>
          </div>

          {/* <motion.div
            initial={{ opacity: 0, y: 200, rotate: 0 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{ duration: 1 }}
            className="flex gap-y-[58px] pt-[80px] w-[70%] mx-auto flex-col justify-between items-center md:flex-row"
          >
            <div className="flex flex-col gap-y-2 justify-center items-center">
              <p className="text-[#02071A] text-[40px] font-[500] fcr">{value}</p>
              <p className="text-[#02071A] text-[20px] font-[400] fcl opacity-80">
                Items
              </p>
            </div>
            <div className="flex flex-col gap-y-2 justify-center items-center">
              <p className="text-[#02071A] text-[40px] font-[500] fcr">
                Jul 2022
              </p>
              <p className="text-[#02071A] text-[20px] font-[400] fcl opacity-80">
                Started in
              </p>
            </div>
            <div className="flex flex-col gap-y-2 justify-center items-center">
              <p className="text-[#02071A] text-[40px] font-[500] fcr">{data.value}</p>
              <p className="text-[#02071A] text-[20px] font-[400] fcl opacity-80">
                owners
              </p>
            </div>
          </motion.div> */}
        </div>
      </div>
    </div>
  );
}
