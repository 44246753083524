import React from 'react'

const Failed = ({setPopupOpen,checkout}) => {
  return (
    <div>
        <div className="px-2 md:px-[20px] overflow-y-hidden  max-w-lg">
        <div className="flex justify-center items-center ">
          <img
            className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
            src="./failed.svg"
            alt=""
            srcset=""
          />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center">
          <p className="text-[#02071A] text-xl md:text-[28px] font-[600] pt-10 md:pt-[56px] pb-[25px] text-center">
            Sorry, Payment Failed!
          </p>
          <p className="text-[#02071A] opacity-80 text-xs md:text-[18px] font-[500] text-center">
            Try again to pay and boost your account.
          </p>
          <div className="flex pt-12 items-center justify-between  pb-3 bg-white w-full">
            <button
              onClick={() => setPopupOpen(false)}
              className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-4 md:px-[22px] py-2.5 md:py-[14px] text-[18px] font-[600]"
            >
              Cancel
            </button>
            <button
              onClick={() => checkout(true)}
              className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-6 md:px-[52px] py-2.5 md:py-[14px] text-[18px] font-[600]"
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Failed