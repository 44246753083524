import axios from 'axios';
export  const getTransactionsByWalletAddress = async (walletAddress) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/transactions/get-transactionsmonth-by-walletAddress`, {
      walletAddress: walletAddress,
    });

    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error; 
  }
};


