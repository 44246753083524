import Header from "./Header";
import Hero from "./Hero";
import Steps from "./Steps";
import Community from "./Community.jsx";
import Benefits from "./Benefits.jsx";
import Footer from "components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function LandingPage() {
  const location = useLocation();
  useEffect(() => {
    const selectedSection = window.location.hash;
    if (selectedSection) {
      const targetElement = document.querySelector(selectedSection);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);
  const [disableScrolling, setDisableScrolling] = useState(false);

  return (
    <div
      className="overflow-x-hidden bg-primary"
    >
      <Header setDisableScrolling={setDisableScrolling} />
      <Hero />
      <Steps />
      <div className="bg-[#F6F7FB]">
        <Community />
        <Benefits />
      </div>
      <Footer />
    </div>
  );
}
