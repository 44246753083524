import React from "react";

function BoxNumber({ t1 }) {
  return (
    <div className="w-[160px] h-[160px] rounded-[24px] text-[#EFEFF3] text-[16px] font-[700] n1   bg-[#627EEA]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
      >
        <rect
          x="0.618774"
          y="0.62793"
          width="48"
          height="48"
          rx="24"
          fill="white"
        />
        <path
          d="M24.5 29.6667L33.3667 24.7641L24.5 11.0001L15.6334 24.7641L24.5 29.6667Z"
          fill="#627EEA"
        />
        <path
          d="M24.4976 39L33.3667 26.8667L24.4976 31.9514L15.6334 26.8667L24.4976 39Z"
          fill="#627EEA"
        />
      </svg>
    </div>
  );
}

export default BoxNumber;
