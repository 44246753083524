import { Cross } from 'icons'
import React from 'react'

const StakePopupforEvolve2 = ({setShowStakePopup}) => {
  return (
    <div>
         <div className='fixed z-[1000] top-0 h-screen flex items-center justify-center bg-black left-0 right-0 w-full  bg-opacity-40'>
      <div className='bg-white max-w-[400px] lg:w-[400px] rounded-2xl p-6 flex justify-center items-center flex-col'>
        <div
          className='w-full  flex mb-2  justify-end cursor-pointer'
          onClick={() => setShowStakePopup(false)}
        >
            <div className=' outline rounded-full'>
          <Cross />
          </div>
        </div>
       <div>
        <p className='text-lg my-4'>Staking is paused for a while until the next evolution is ready.</p>
       </div>
       
      </div>
    </div>
    </div>
  )
}

export default StakePopupforEvolve2