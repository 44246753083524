export const NFT = () => {
  return (
    <div className="p-4 w-[145px] sm:w-[220px] bg-white h-max rounded-2xl flex flex-col sm:gap-2 animate-pulse">
      <div className="w-full h-[110px] sm:h-[180px] bg-gray-300 rounded-[16px]"></div>
      <div className="h-4 bg-gray-300 rounded mt-2 w-3/4"></div>
      <div className="flex mt-2 space-x-2">
        <div className="h-8 bg-gray-300 rounded w-1/2"></div>
        <div className="h-8 bg-gray-300 rounded w-1/4"></div>
      </div>
    </div>
  );
};
