import React from "react";
import { motion } from "framer-motion";

function SecondBenefitsCard({ img, t1, t2 }) {
  return (
    <motion.div
      // initial={{ opacity: 0, x: -300, rotate: 0 }}
      // whileInView={{
      //   opacity: 1,
      //   x: 0,
      // }}
      // viewport={{ once: true }}
      // transition={{ duration: 0.9 }}
      className={`w-[100%] md:w-[400px] md:h-[526px] h-[560px] px-[24px] md:mx-[10px] mx-[20px] bg-[#FFF] rounded-[24px] py-[32px] flex flex-col items-center ${
        img == "/b1.png" ? "mt-[25px]" : ""
      }`}
    >
      <motion.p class="text-[#02071A] text-[25px] font-[600] mt-2">
        {t1}
      </motion.p>
      <div className={`${img == "/all_the_eggs_final.png" ? "px-[62px] py-[9px] mb-[25px] rounded-[24px] bg-[#EFEFF3] mt-3" : "px-[62px] py-[9px] mb-[25px] rounded-[24px] bg-[#EFEFF3] mt-3"}`}>
        <img
          className={`object-contain pt-[12px] pb-[24px] ${
            img == "/all_the_eggs_final.png" ? "w-[234px] h-[234px]" : "w-[234px] h-[234px]"
          } `}
          src={img}
          alt=""
        />
      </div>
      <p className="text-[#02071A] text-[16px] font-[400] opacity-70 pt-[2px]">
        {t2}
      </p>
    </motion.div>
  );
}

export default SecondBenefitsCard;
