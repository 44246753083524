import axios from "axios";

export const fetchDetails = async (address) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/user/user-details/${address}`
      );
      if (response.data?.userDetails) {
        return response.data?.userDetails;
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };