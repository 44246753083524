import React from "react";
import { motion } from "framer-motion";
import BoxNumber1 from "./BoxNumber1";

function OptionCard1() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 200, rotate: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="bg-[#EFEFF3] mt-[29px] flex justify-center flex-col items-center w-[396px] h-[400px] mx-[20px] rounded-[24px]"
    >
      <motion.div
        initial={{ opacity: 0, x: -100, rotate: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className="flex flex-col items-center justify-center p-[24px]"
      >
        <p class="text-[#02071A] text-[25px] font-[600]">Option 2</p>

        <p class="text-[#02071A] text-center px-6 mt-[14px] mb-[36px] text-[14px] font-[400]">
        Own and stake 5 squares to instantly evolve for free.
        </p>
        {/* <div class="w-[100%] mt-[18px]  relative h-[122px] flex flex-col items-center justify-center space-y-1 bg-[#FFF] rounded-[16px]">
          <img
            className="absolute top-[-42px]"
            src="/il.svg"
            alt=""
            srcset=""
          />
          <div className="n2 space-x-[6px]">
            <p className="text-[#02071A] text-[18px] font-[500]">You</p>
            <img
              className="object-contain w-4"
              src="/twittersvg.svg"
              alt=""
              srcset=""
            />
          </div>
          <p className="text-center text-[#02071A] text-[16px] font-[400] opacity-70 pt-[3px]">
            @username
          </p>
        </div> */}
        <div className="grid gap-[24px] mt-[1px] grid-cols-3 grid-rows-2">
          
          {/* First row */}
          <BoxNumber1 t1="1" />
          <BoxNumber1 t1="2" />
          <BoxNumber1 t1="3" />
          {/* Second row (centered) */}
          <div className="col-start-1 col-end-4 gap-[24px] flex justify-center">
            <BoxNumber1 t1="4" />
            <BoxNumber1 t1="5" />
          </div>
        </div>
        {/* <p class="text-[#02071A] text-center mt-[28px] text-[14px] font-[400]">
        Instant evolution if you have less then <br /> 5 Squares..
      </p> */}
      </motion.div>
    </motion.div>
  );
}

export default OptionCard1;
