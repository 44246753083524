import React from "react";
import BoxNumber from "./BoxNumber";
import { motion } from "framer-motion";

function OptionCard2() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100, rotate: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="bg-[#EFEFF3] flex justify-center flex-col items-center w-[396px] h-[400px]  mx-[20px] rounded-[24px]"
    >
      <motion.div
        initial={{ opacity: 0, x: 50, rotate: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center justify-center p-[24px]"
      >
        <p class="text-[#02071A] text-[25px] font-[600]">Option 3</p>

        <p class="text-[#02071A] leading-24 text-center mt-[16px] mb-[23px] text-[14px] font-[400]">
          Pay the instant evolution fee. Pay a fee to avoid waiting 30 days or
          if you’re staking less than 5 squares.
        </p>
        <div className="flex justify-center items-center">
          {/* grid gap-[24px] mt-[9px] grid-cols-3 grid-rows-2 */}
          {/* First row */}
          <BoxNumber t1="1" />
          {/* <BoxNumber t1="2" />
          <BoxNumber t1="3" /> */}
          {/* Second row (centered) */}
          {/* <div className="col-start-1 col-end-4 gap-[24px] flex justify-center">
            <BoxNumber t1="4" />
            <BoxNumber t1="5" />
          </div> */}
        </div>
        {/* <p class="text-[#02071A] text-center mt-[28px] text-[14px] font-[400]">
        to evolve for free.
      </p> */}
      </motion.div>
    </motion.div>
  );
}

export default OptionCard2;
