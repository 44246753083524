import React from "react";

function LoginPage() {
  return (
    <div className="flex flex-col px-9 h-screen md:w-[602px] mx-auto">
      <div className="my-4 mt-[24px] mb-[38px]">
        <p className="text-white text-[32px] flex items-center justify-center font-[700]">
          627EEA
        </p>
      </div>
      <div className="rounded-2xl bg-white shadow-xl md:p-[39px] p-6">
        <p className="text-[#02071A] flex justify-center items-center text-[38px] mb-6 font-[600]">
          Change Password
        </p>
        <div>
          <div className="space-y-[8px] mt-4">
            <label class="text-[16px] text-[#02071A] font-[500]">
              New Password
            </label>
            <div
              style={{ borderColor: "rgba(2, 7, 26, 0.08)" }}
              class="flex  border w-full p-[15px] rounded-[8px] px-[14px] focus:border border-blue-500 text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
            >
              <input
                type="password"
                class="block border focus:border-none focus:outline-none  w-full  border-none rounded-[8px]  text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
                placeholder="*****************"
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
          </div>
          <div className="space-y-[8px] mt-4">
            <label class="text-[16px] text-[#02071A] font-[500]">
              Confirm Password
            </label>
            <div
              style={{ borderColor: "rgba(2, 7, 26, 0.08)" }}
              class="flex  border w-full p-[15px] rounded-[8px] px-[14px] focus:border border-blue-500 text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
            >
              <input
                type="password"
                class="block border focus:border-none focus:outline-none  w-full  border-none rounded-[8px]  text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
                placeholder="*****************"
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
          </div>
          <button className="bg-[#627EEA] mt-[40px] mb-6 text-white text-[18px] font-[600] rounded-[8px] w-full p-[15px]">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
