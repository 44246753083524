import { Boxloader } from 'common'
import React from 'react'

const LoadingPopup = ({loading}) => {
  return (
    <div><div className="px-6 overflow-y-hidden max-w-lg">
    <div className="flex justify-center items-center ">
      <Boxloader />
    </div>
    <div className="flex flex-col mb-5 justify-center items-center w-full">
      <p className="text-[#02071A] text-sm md:text-lg font-[600] pt-16 pb-10 text-center">
        {loading ? (
          "Please wait while we redirect you to payment page..."
        ) : (
          <span>
            Please wait until we process your transaction...
            <br />
            Do not refresh or close your browser.
          </span>
        )}
      </p>
    </div>
  </div></div>
  )
}

export default LoadingPopup