import React from "react";

function BlueBox1({ src,mobile }) {
  return (
    <div>
      <div className={`${mobile? "w-[120px] h-[120px]":"w-[100px] h-[100px]"}  p-2 flex justify-center items-center  bg-[#627EEA] rounded-[24px]`}>
       {src&& <img src={src} alt="My GIF" />}
      </div>
    </div>
  );
}

export default BlueBox1;
