import React from "react";

function Terms() {
  return (
    <div className="space-y-4 px-3 md:px-8">
      <p className="h1">1. Introduction</p>
      <div className="space-y-2">
        <p className="h2">1.1 Acceptance of Terms</p>
        <p className="p">
          By accessing or using 627EEA ("Boost"), you agree to comply with and
          be bound by these Terms of Service ("Terms"). If you do not agree to
          these Terms, please do not purchase the Boost.
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">1.2 Changes to Terms</p>
        <p className="p">
          627EEA reserves the right to update or modify these Terms at any time
          without prior notice. It is your responsibility to review these Terms
          periodically for updates. You may request the terms of service
          agreement at any time.
        </p>
      </div>
      <p className="h1">2. Use of the Service</p>
      <div className="space-y-2">
        <p className="h2">2.1 Eligibility</p>
        <p className="p">
          By purchasing the Boost, you represent and warrant that you are of
          legal age to form a binding contract with 627EEA.
        </p>
      </div>
      <p className="h1">3. Boost Activation and Membership Enrollment</p>
      <div className="space-y-2">
        <p className="h2">
          3.1 Boost Activation Period:&nbsp;
          <span className="p">
            The Boost, as described herein, shall remain active from the moment
            of purchase until the 28th day of each calendar month.
          </span>
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">
          3.2 Membership Enrollment Timing:&nbsp;
          <span className="p">
            Any membership purchases made after the 14th day of a calendar month
            may be subject to a waiting period until the commencement of the
            following month before the purchaser is added as an associate to our
            organization. During this waiting period, certain benefits and
            privileges associated with membership, including participation in
            our organization, may not be accessible.
          </span>
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">3.3 Waiting Period and Ongoing Membership</p>
        <p className="h2">
          3.3.1 Waiting Period:&nbsp;
          <span className="p">
            The waiting period mentioned in Section 3.2 places new Boost members
            on a waiting list for enrollment in our exclusive benefits program,
            which begins on the first day of the next calendar month following
            their Boost purchase. During this waiting period, users will not
            have access to the exclusive benefits reserved for Boosted members.
          </span>
        </p>
      </div>

      <div className="space-y-2">
        <p className="h2">
          3.3.2 Membership Retention:&nbsp;
          <span className="p">
            To maintain their Boosted membership status and continue enjoying
            the exclusive benefits, every user must Boost again by the 28th day
            of each calendar month. Failure to Boost during this period may
            result in the loss of Boosted membership privileges until the next
            Boost is completed.
          </span>
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">3.4 User Accounts</p>
        <p className="p">
          a. You may be required to create a user account to access certain
          features of a Boosted account. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
          <br />
          <br /> b. You agree to provide accurate, current, and complete
          information when creating your account and to update your information
          as necessary to keep it accurate, current, and complete.
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">3.5 Prohibited Activities</p>
        <p>You agree not to:</p>
        <ul className="p space-y-2">
          <li>
            a. Purchase the Boost for any illegal or unauthorized purpose.
          </li>
          <li>b. Violate any applicable laws, rules, or regulations.</li>
          <li>
            c. Interfere with or disrupt the integrity or performance of its
            related systems.
          </li>
          <li>
            d. Attempt to gain unauthorized access to a Boosted or any unboosted
            account.
          </li>
          <li>
            e. Engage in any form of harassment, hate speech, or discrimination.
          </li>
          <li>
            f. Impersonate any person, entity or misrepresent your affiliation
            with any person or entity.
          </li>
        </ul>
      </div>
      <div className="space-y-2">
        <p className="h2">4. Termination</p>
        <p className="p">
          627EEA reserves the right to terminate or suspend your access to the
          Boost at its sole discretion, without notice, for any reason,
          including but not limited to a breach of these Terms.
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">5. Disclaimers and Limitations of Liability</p>
        <p className="p">
          627EEA provides the Boost "as is" and disclaims all warranties,
          express or implied. In no event shall 627EEA be liable for any direct,
          indirect, incidental, special, or consequential damages arising out of
          or in connection with your use of the Boosted account.
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">6. Governing Law</p>
        <p className="p">
          These Terms shall be governed by and construed in accordance with the
          laws of Delaware, without regard to its conflict of law principles.
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">7. Representation of Our Organization</p>
        <p className="p">
          At 627EEA, we value the authentic voices and contributions of our
          members who support and engage with our mission. Through our Boost
          membership program, we offer individuals the opportunity to not only
          access exclusive benefits but also become official representatives of
          our organization.
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">7.1 Affiliation with Our Organization</p>
        <p className="p">
          Boosted members can be considered affiliates on X as they fit specific
          requirements that make them officially associated to our organization.
          As affiliates, they proudly display a small image of our
          organization's account profile picture next to their name whenever
          they post, send direct messages, or appear in search results on the
          platform. This affiliation badge signifies their association with and
          support for our organization's goals and values.
          <br />
          <br />
          We extend this affiliation privilege to individuals who are Boosted
          members, recognizing their commitment to our cause and their desire to
          represent us on X. It is important to note that only individuals who
          genuinely represent or are associated with our organization, as
          determined by us, will be invited to become affiliates. We reserve the
          right to reject any membership application without providing a reason
          for such rejection.
          <br />
          <br />
          In the event of rejection of a Boosted membership, we will process
          refunds for the Boost; however, please note that we will not refund
          any NFTs or other associated purchases made as part of the Boost
          package.
          <br />
        </p>
      </div>
      <div className="space-y-2">
        <p className="h2">7.2 Reasons for Membership Rejection</p>
        <p className="p">
          We reserve the right to reject Boost for any reason including but not
          limited to:
        </p>
      </div>
      <div>
        <p className="h2">
          Spam Account:&nbsp;
          <span className="p">
            Accounts that are primarily used for spamming or engaging in
            deceptive practices.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          Engagement Farming Account:&nbsp;
          <span className="p">
            Accounts that exist solely for the purpose of artificially inflating
            engagement metrics.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          Posting Hacks/Malicious Content:&nbsp;
          <span className="p">
            Accounts that exist solely for the purpose of artificially inflating
            engagement metrics.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          Posting Hateful Content:&nbsp;
          <span className="p">
            Accounts that consistently post content promoting hate speech,
            discrimination, or violence.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          Engaging in Activity Against X's Policies:&nbsp;
          <span className="p">
            Accounts that engage in activities that are in violation of the
            platform or our organization's policies and values.
            <br />
            <br />
            These are some common reasons for rejection, but we may exercise to
            reject membership applications for other reasons as well. We have
            discretion to reject members as deemed necessary to maintain the
            integrity and values of our organization.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">8. Exclusive Benefits for Boosted Members</p>
        <p className="h2 my-4">
          Boosted members of 627EEA ("Boost") are entitled to exclusive benefits
          provided by our organization, subject to the terms and conditions
          outlined in this Agreement.
        </p>
        <p className="p">
          The exclusive benefits available to Boosted Members may include, but
          are not limited to, the following:
        </p>
      </div>
      <div>
        <p className="h2">
          <span className="p">a.&nbsp;</span> Early Access:&nbsp;
          <span className="p">
            Boosted Members may receive early access to new features, products,
            or services offered by 627EEA.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          <span className="p">b.&nbsp;</span> Special Discounts:&nbsp;
          <span className="p">
            Boosted Members may be eligible for special discounts or pricing on
            certain products or services offered by 627EEA.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          <span className="p">c.&nbsp;</span> Priority Support:&nbsp;
          <span className="p">
            Boosted Members may receive priority customer support, with faster
            response times and dedicated assistance.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">
          <span className="p">d.&nbsp;</span> Affiliation Access:&nbsp;
          <span className="p">
            Boosted Members represent the organization. This representation can
            be associated with our official affiliate badge.
          </span>
        </p>
      </div>
      <div className="flex gap-x-2">
        <p className="h2">
          8.2 NFT Staking Requirement:&nbsp;
          <span className="p">
            To access or maintain Boosted membership status and continue
            enjoying the exclusive benefits, each Boosted member must stake at
            least one 627EEA NFT in their account. Failure to meet this staking
            requirement may result in the loss of Boosted membership privileges
            and association with our organization until the staking requirement
            is fulfilled.
          </span>
        </p>
      </div>
      <div className="flex gap-x-2">
        <p className="h2">
          8.3&nbsp;
          <span className="p">
            Boosted Members must adhere to the terms and conditions of this
            Agreement and any additional terms and conditions associated with
            specific benefits provided to them.
          </span>
        </p>
      </div>
      <div className="flex gap-x-2">
        <p className="h2">
          8.4 Revocation of Benefits:&nbsp;
          <span className="p">
            627EEA reserves the right to revoke or modify Boosted Members'
            access to exclusive benefits at its sole discretion, without notice,
            for any reason, including but not limited to a breach of these Terms
            or failure to meet the NFT staking requirement.
            <br />
            <br />
            By becoming a Boosted Member and accessing exclusive benefits, you
            acknowledge and agree to comply with the terms and conditions
            outlined in this section.
          </span>
        </p>
      </div>
      <div>
        <p className="h2">9. Contact Information</p>
        <p className="p mt-2">
          If you have any questions about these Terms or ("Boost"), please
          contact us on our official X account @627EEA and we will respond as
          soon as possible.
        </p>
      </div>
    </div>
  );
}

export default Terms;
