import React from 'react'

const PaymentMobile = () => {
  return (
    <div>
        <div className="px-6 overflow-y-hidden max-w-lg">
        <div className="flex justify-center items-center ">
          <img
            className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
            src="./failed.svg"
            alt=""
            srcset=""
          />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center w-full">
          <p className="text-[#02071A] text-sm md:text-3xl font-[600] pt-16 pb-10 text-center">
            Please complete your payment from a laptop/desktop device.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PaymentMobile