import React from "react";

function BlueBox({ src,mobile }) {
  return (
    <div>
      <div className={`${mobile? "w-[140px] h-[140px]":"w-[210px] h-[210px]"}  p-2 flex justify-center items-center  bg-[#627EEA] rounded-[24px]`}>
       {src&& <img src={src} alt="My GIF" />}
      </div>
    </div>
  );
}

export default BlueBox;
