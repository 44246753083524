import {purpledex } from 'api/purpledex';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import CatEgg from "../assets/images/eggs/null cat.png";
import Chicken from "../assets/images/eggs/null chicken.png";
import SharkEgg from "../assets/images/eggs/null shark.png";
import CrocEgg from "../assets/images/eggs/null croc.png";
import DragEgg from "../assets/images/eggs/null dragon.png";
import ImageGridPurpleDex from './ImageGridPurpleDex';
import { useUser } from 'context/user';
import { Loader } from 'common';
const Eggs = ({nftImages}) => {
  // const {user:userDetails} = useUser();
  // useEffect(() =>{
  //   setAddress(userDetails?.walletAddress)
  // },[userDetails?.walletAddress])
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    const myFunction = () => {
      setLoading(true)
    };
    const intervalId = setInterval(myFunction, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
   <>
    { loading === false   ?<div className='h-[200px] w-[400px] flex items-center justify-center'><Loader /></div> :
    <div className='flex flex-col'>
    <ImageGridPurpleDex images={nftImages?.chicken|| []} defaultImage={Chicken} alt="Chicken" />        
    <ImageGridPurpleDex images={nftImages?.cat || []} defaultImage={CatEgg} alt="Cat" />
    <ImageGridPurpleDex images={nftImages?.croc || []} defaultImage={CrocEgg} alt="Croc" />
    <ImageGridPurpleDex images={nftImages?.dragon|| []} defaultImage={DragEgg} alt="Dragon" />
    <ImageGridPurpleDex images={nftImages?.shark || []} defaultImage={SharkEgg} alt="Shark" />
  </div>
   }</>
  )
};
export default Eggs