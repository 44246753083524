import React from "react";

function Cross() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g opacity="0.8">
        <path
          d="M6 5L19.9999 18.9999"
          stroke="#02071A"
          stroke-width="2"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.00012 18.9999L20 5"
          stroke="#02071A"
          stroke-width="2"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default Cross;
