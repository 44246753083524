import { useEffect, useState } from "react";
import TableData from "components/TableData";
import axios from "axios";


export default function Transactions() {
  const [twitterUpdate, setTwitterUpdate] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const [transactionData, setTransactionData] = useState({
    data: [],
    loading: true,
  });

  const [type, setType] = useState("boost");
  const [status, setStatus] = useState("");
  const onclinkHandler = (type) => {
    setType(type);
    setStatus("");
  };

  const fetchTransactions = () => {
    axios
    .post(`${process.env.REACT_APP_PUBLIC_URL}/transactions/get-transations`, {
      type: type || null,
      status: status || null,
      startDate: selectedStartDate || null,
      endDate: selectedEndDate || null,
    })
    .then((res) => {
      const data = res.data;


      if (data.statusCode === 200) {
        const sortData = data?.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setTransactionData({
          data: sortData,
          loading: false,
        });
      } else {
        setTransactionData({
          data: [],
          loading: false,
        });
      }
    })
    .catch((err) => {
      setTransactionData({
        data: [],
        loading: false,
      });
    });
  };
  

  useEffect(() => {
    fetchTransactions();
  }, [type, status, twitterUpdate, selectedStartDate && selectedEndDate]);
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <>
      <div className="h-full w-full flex justify-center items-center ">
        <div className="rounded-[16px] w-full   h-[80vh]  lg:w-[1200px] 2xl:w-[1550px]  bg-white flex flex-col justify-center items-center ">
          <div className="flex justify-between p-[24px] w-full">
            <div className="flex">
              <p className=" p-3 w-full text-[#02071A] text-[23px] font-[600]">
                Transactions
              </p>
              <div className="flex justify-center items-center">
                <button
                  className={
                    type === "boost"
                      ? "bg-primary  text-white px-5 border-[1px] border-[#EBECED]  py-3 rounded-xl mx-2"
                      : "bg-white px-5 border-[1px] border-[#EBECED]  py-3 rounded-xl mx-2"
                  }
                  onClick={() => {
                    onclinkHandler("boost");
                  }}
                >
                  Boost
                </button>
                <button
                  className={
                    type === "evolve"
                      ? "bg-primary text-white px-5 border-[1px] border-[#EBECED]  py-3 rounded-xl mx-2"
                      : "bg-white px-5 border-[1px] border-[#EBECED]  py-3 rounded-xl mx-2"
                  }
                  onClick={() => {
                    onclinkHandler("evolve");
                  }}
                >
                  Evolve
                </button>
              </div>
            </div>
            <div className="flex">
              <div className="flex items-center  justify-center  w-[500px]">
                <label className="w-full ml-2 -mr-12" htmlFor="">
                  Start Date
                </label>
                <input
                  className="w-full border p-2 m-0 bg-primary rounded-md focus:outline-none text-white focus:text-white focus:border-blue-500"
                  type="date"
                  value={selectedStartDate}
                  max={currentDate}
                  onChange={(e) => setSelectedStartDate(e.target.value)}
                  calendarClassName="custom-calendar "
                />
                <label className="w-full ml-2 -mr-12" htmlFor="">
                  End Date:
                </label>
                <input
                  className="w-full border p-2  bg-primary rounded-md focus:outline-none text-white focus:text-white focus:border-blue-500"
                  type="date"
                  value={selectedEndDate}
                  max={currentDate}
                  onChange={(e) => setSelectedEndDate(e.target.value)}
                  calendarClassName="custom-calendar "
                />
              </div>
              <div
                className="flex justify-center items-center mx-2 cursor-pointer"
                onClick={() => {
                  setStatus("confirmed");
                }}
              >
                <p className="bg-[#0AD406] h-5 w-5 rounded-full"></p>
                <p className="mx-2">Confirmed</p>
              </div>
              <div
                className="flex justify-center items-center mx-2 cursor-pointer"
                onClick={() => {
                  setStatus("failed");
                }}
              >
                <p className="bg-[#FF154D] h-5 w-5 rounded-full"></p>
                <p className="mx-2">Failed </p>
              </div>
              <div
                className="flex justify-center items-center mx-2 cursor-pointer"
                onClick={() => {
                  setStatus("pending");
                }}
              >
                <p className="bg-[#F0E15B] h-5 w-5 rounded-full"></p>
                <p className="mx-2">Pending</p>
              </div>
            </div>
          </div>
          <div className="max-h-[calc(100dvh-240px)] 2xl:max-h-[calc(100dvh-310px)] ml-2 lg:w-[1100px] lg:overflow-x-scroll 2xl:w-[1500px]  h-full overflow-y-auto hide-scrollbar">
            <TableData
              setTwitterUpdate={setTwitterUpdate}
              twitterUpdate={twitterUpdate}
              transactionData={transactionData}
              keys={[
                {
                  label: "NO.",
                  key: "index",
                },
                {
                  label: "TWITTER USERNAME",
                  key: "twitter",
                },
                {
                  label: "WALLET",
                  key: "walletAddress",
                },
                {
                  label: "amount",
                  key: "amount",
                },
                {
                  label: "Transaction ID",
                  key: "trans_id",
                },

                {
                  label: "created at",
                  key: "created_at",
                },
                {
                  label: "status",
                  key: "status",
                },
              ]}
              data={
                transactionData?.data?.length > 0
                  ? transactionData?.data?.map((item, index) => ({
                      ...item,
                      index: index + 1,
                      created_at: new Date(item.created_at).toLocaleString(),
                    }))
                  : []
              }
              fetchTransactions={fetchTransactions}
            />
          </div>
        </div>
      </div>
    </>
  );
}

