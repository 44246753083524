import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useState } from "react";
import { Animation } from "common";

import { Link, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

export default function IndexPage() {
  const { address } = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    if (address) {
      navigate("/Verify", { state: { address } });
    }
  }, [address, navigate]);

  const [checked, setChecked] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );

  useEffect(() => {
    // Update the body background color when the switch is toggled
    if (checked) {
      document.body.style.backgroundColor = "#F6F7FB";
    } else {
      document.body.style.backgroundColor = "#627EEA"; // Reset to the default background color
    }

    // Save the state in localStorage
    localStorage.setItem("darkMode", JSON.stringify(checked));
  }, [checked]);

  return (
    <div className="overflow-x-hidden bg-primary h-screen">
      <div className="h-screen w-screen ">
        <div className="flex pt-12 justify-center items-center">
          <Link to="/">
            <p className="text-white font-[600] text-3xl font-montrealMedium ">
              627EEA
            </p>
          </Link>
        </div>
        <div className="flex flex-col justify-center items-center gap-24 h-[calc(100dvh-84px)]">
          <p className="text-2xl md:text-4xl lg:text-6xl font-semibold capitalize bg-transparent text-white text-center">
            More than just a square.
          </p>
          <div
            style={{ backgroundColor: "rgba(255, 255, 255, 0.25)" }}
            className=" bg-transparent rounded-2xl border-2 border-gray py-2 lg:py-3 px-8 capitalize font-semibold text-xl text-white"
          >
            {/* connect wallet */}
            <ConnectButton label="Verify Membership" />
            <p>{address}</p>
          </div>
        </div>
      </div>
      <Animation className={`!bg-[#ffffff33]`} />
    </div>
  );
}
