import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Moon from "./Moon";

function Mode({checked,setChecked}) {
  // Initialize the state from localStorage or set it to false if not present
  // const [checked, setChecked] = useState(
  //   JSON.parse(localStorage.getItem("darkMode")) || false
  // );

  const handleChange = (newChecked) => {
    setChecked(newChecked);
  };

  useEffect(() => {
    // Update the body background color when the switch is toggled
    if (checked) {
      document.body.style.backgroundColor = "#F6F7FB";
    } else {
      document.body.style.backgroundColor = "#627EEA"; // Reset to the default background color
    }

    // Save the state in localStorage
    localStorage.setItem("darkMode", JSON.stringify(checked));
  }, [checked]);

  return (
    <div className="font-poppins">
      <label htmlFor="small-radius-switch">
        <Switch
          onChange={handleChange}
          checked={checked}
          handleDiameter={20}
          offColor="#F6F7FB"
          onColor="#F6F7FB"
          // offHandleColor="#627EEA80"
          // onHandleColor="#627EEA80"
          height={48}
          width={70}
          borderRadius={10}
          activeBoxShadow="0px 0px 1px 2px #fffc35"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                fontFamily: "Poppins",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 14,
                color: "black",
                paddingRight: 32,
              }}
            >
              Light <br /> Mode
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 14,
                color: "black",
                paddingLeft: 30,
              }}
            >
              Purple <br /> Mode
            </div>
          }
          uncheckedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 20,
              }}
            >
              <div className="pl-3">
                <Moon />
              </div>
            </div>
          }
          checkedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                color: "red",
                fontSize: 18,
              }}
            >
              <div className="pl-16">
                <Moon />
              </div>
            </div>
          }
          className="react-switch"
          id="small-radius-switch"
        />
      </label>
    </div>
  );
}

export default Mode;
