import React, { useState } from 'react';

const ImageGridPurpleDex = ({ images, defaultImage, alt }) => {
  
  
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobileHovered, setIsMobileHovered] = useState(false);
  return (
    <div className='flex justify-center flex-wrap w-[500px] md:w-[700px]'>
      {images.map((item, index) => 
      {
      return(
        <div
          key={index}
          className='md:w-[72px] w-[52px] md:h-[95px] h-[95px] relative cursor-pointer'
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick= {() => setIsMobileHovered(!isMobileHovered)}
        >
          <img src={item !== null ? item.imageUrl : defaultImage} alt={alt} />
          {hoveredIndex === index && (
            <div className='lg:absolute lg:block hidden tooltip-text text-xs -top-10 z-[1000] left-10 right-5 border-[1px] border-black bg-white text-black  text-center p-[2px] w-fit bg-opacity-75  '>
              {item?.Rarity }
            </div>
          )}
          {hoveredIndex === index && isMobileHovered && (
            <div className='lg:hidden tooltip-font absolute tooltip-text text-xs  -top-10   left-10 right-5 border-[1px] border-black bg-white text-black  text-center p-[2px] w-fit bg-opacity-75  '>
              {item?.Rarity}
            </div>
          )}
        </div>
      )})}
    </div>
  );
};

export default ImageGridPurpleDex;
