import React from 'react'

function BoxNumber1({t1}) {
  return (
    <div className='w-[70px] h-[70px]  rounded-[24px] text-[#EFEFF3] text-[16px] font-[700] n1   bg-[#627EEA]'>
      {t1}
    </div>
  )
}

export default BoxNumber1