import { motion } from "framer-motion";

function SingleStep({ step, title, img, steps = [] }) {
  return (
    <div className="flex flex-col gap-4 self-start">
      <motion.div className="max-w-[600px] w-full rounded-[24px] bg-[#EFEFF3] flex flex-col items-center pt-6 px-6">
        <motion.h1 className="text-theme-black font-bold text-2xl lg:text-3xl">
          Step {step}
        </motion.h1>
        <motion.p className="text-lg lg:text-2xl font-medium capitalize mt-2">
          {title}
        </motion.p>
        <motion.img
          initial={{ opacity: 0, y: 50, rotate: 0 }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          src={img}
          alt={title}
          className="mt-6 md:h-[252px]"
        />
      </motion.div>
      {steps.map((step, index) => (
        <motion.div
          // initial={{ opacity: 0, y: 200, rotate: 0 }}
          // whileInView={{
          //   opacity: 1,
          //   y: 0,
          // }}
          // transition={{ duration: 0.5 }}
          className={`bg-[#EFEFF3] ${index=== 0 ? "min-h-[120px]" : "min-h-[104px]"} ${index=== 2 ? "min-h-[140px]" : "min-h-[104px]"} rounded-[24px] py-4 px-6 max-w-[600px] w-full min-h-[104px]`}
        >
          <motion.h1
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 2.5 }}
            className="text-theme-black font-semibold"
          >
            Step {index + 1}.
          </motion.h1>
          <motion.ul
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 2.5 }}
            className="md:list-disc "
          >
            {step}
          </motion.ul>
        </motion.div>
      ))}
    </div>
  );
}
export default function Steps() {
  return (
    <div id="how-to-get-started" className="bg-white w-full">
      <div className="max-w-7xl w-full py-20 lg:pb-40 px-6 lg:px-0 mx-auto">
        <motion.p
          initial={{ opacity: 0, x: -50, rotate: 0 }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="font-[400] text-[32px] text-center text-theme-black leading-9 lg:leading-[52px]"
        >
          Simple <span className="font-semibold">Steps</span> to&nbsp;
          <span className="font-semibold">Own </span>
          {/* <br /> */}
          Your First
          <span className="font-semibold"> NFT</span>
        </motion.p>
        <div className="mt-12 justify-center flex flex-col lg:flex-row items-center gap-9 lg:gap-12 flex-wrap">
          <SingleStep
            step="1"
            title="Buy Crypto"
            img="/step-1.png"
            steps={[
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Sign up with a trustworthy exchange. Ex.&nbsp;
                <span className="font-semibold">
                  Coinbase, Binance, Crypto.com
                </span>
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Verify your account and deposit funds. You may have to wait 1-2
                days.
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Once the account is verified, buy crypto. Buy&nbsp;
                <span className="font-semibold">ETH, USDC or USDT </span>
                For buying NFTS, you'll want to have ETH* 
              </li>,
            ]}
          />
          <SingleStep
            step="2"
            title="Setup Metamask Wallet"
            img="/step-2.png"
            steps={[
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Download the extension MetaMask.
                <br />
                <span className="font-semibold">
                  *You're creating a wallet for crypto*
                </span>
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Create a wallet & copy the seed phrase.{" "}
                <span className="font-semibold">
                  *DO NOT LOSE THESE 12 WORDS*
                </span>
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Send crypto from your exchange to here. Once funded you can buy
                NFTs and pay for the instant evolution fee in crypto if you
                decide to use it.
              </li>,
            ]}
          />
          <SingleStep
            step="3"
            title="Buy Our NFT"
            img="/step-3.png"
            steps={[
              <li className="text-theme-black mt-1 md:ml-8 text-sm lg:text-base">
                Go to OpenSea.
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Find a square that is available. Purchase your{" "}
                <span className="font-semibold">627EEA square.</span>
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Once you've purchased your square you can connect to our 'verify
                membership' portal.
              </li>,
            ]}
          />
          <SingleStep
            step="4"
            title="Verify Membership"
            img="/step-4.png"
            steps={[
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Access the{" "}
                <span className="font-semibold">'Membership'</span>{" "}
                portal. Connect your meta mask wallet.
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Stake your square(s) or pay the evolution fee to evolve.
              </li>,
              <li className="text-theme-black md:ml-8 text-sm lg:text-base">
                Staking your square(s) for 30 days will allow you to upgrade for
                free. Paying the evolution fee will allow you to evolve
                instantly.
              </li>,
            ]}
          />
        </div>
      </div>
    </div>
  );
}
