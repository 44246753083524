import { Button, Logo } from "common";
import { useEffect, useState } from "react";
import { Twitter } from "icons";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { MenuCross, Hamburger } from "icons";
import Mode from "components/Mode";
import Popup from "components/Popup";
import { signIn, logout, unsubscribe } from "../firebase";

export default function Header({ checked, setChecked, rigtbutton }) {
  const [name, setName] = useState(null);
  const [showlogout, setshowlogout] = useState(false);

  useEffect(() => {
    const UserName = localStorage.getItem("UserName");
    if (UserName) {
      const name = JSON.parse(UserName);
      setName(name);
    }

    // Clean up the listener
    return () => unsubscribe();
  }, []);

  const HeaderRightContainer = () => {
    let data = {
      confirm: (
        <div className="px-2 md:px-12 z-50">
          <p className="text-[#02071A] mt-5 flex justify-center items-center text-center font-[600] text-xl md:text-[30px] max-w-lg">
            Are you sure you want to disconnect your X account?
          </p>
          <img
            className="mt-[32px] mb-[48px]"
            src="./confirm.svg"
            alt=""
            srcset=""
          />
          <div className="flex flex-col gap-y-6 justify-center items-center">
            <Button
              onClick={() => logout()}
              className="py-3 w-[170px] rounded-[8px] font-[600] border bg-primary text-white"
            >
              Yes, Confirm
            </Button>
            <Button
              onClick={() => togglePopup()}
              className="py-3 w-[170px] border-none rounded-[8px] flex-1 hover:text-white  hover:bg-primary text-black"
            >
              No
            </Button>
          </div>
        </div>
      ),
      signInFromMobile: (
        <div className="px-6 overflow-y-hidden max-w-lg">
          <div className="flex justify-center items-center ">
            <img
              className="w-[75px] md:w-[90px] h-[75px] md:h-[90px] object-contain"
              src="./failed.svg"
              alt=""
              srcset=""
            />
          </div>
          <div className="flex flex-col mb-5 justify-center items-center w-full">
            <p className="text-[#02071A] text-sm md:text-3xl font-[600] pt-16 pb-10 text-center">
              In order to sign in, please connect your X account on a
              laptop/desktop device.
            </p>
          </div>
        </div>
      ),
    };
    const [isPopupOpen, setPopupOpen] = useState(false);

    const [selectedtextpopup, setselectedtextpopup] = useState("confirm");
    const togglePopup = () => {
      setPopupOpen(!isPopupOpen);
    };
    return (
      <div className="items-start lg:items-center gap-4 flex flex-col lg:flex-row pl-10 lg:pl-0">
        <Link to={rigtbutton ? `/${rigtbutton}` : "/purpledex"}>
          <Button className="rounded-[32px] py-[15px] font-poppins  px-10 text-[16px] capitalize">
            {rigtbutton ?? "Purplédex"}
          </Button>
        </Link>
        {name ? (
          <div className="relative">
            <div
              onClick={() => {
                setselectedtextpopup("confirm");
                togglePopup();
              }}
            >
              <Button className="px-12 rounded-[32px]">
                <Twitter />
                Connected
              </Button>
            </div> 
            {showlogout && (
              <div
                onClick={() => logout()}
                className="cursor-pointer absolute px-[52px] mt-[12px] flex items-center justify-center gap-2 text-white text-lg font-medium p-4 border border-gray border-opacity-80 rounded-md bg-[#627EEA] bg-opacity-70"
              >
                Logout
              </div>
            )}
          </div>
        ) : (
          <Button
            className="font-poppins rounded-[32px] text-[16px]"
            onClick={() => {
              if (window.innerWidth < 768) {
                setselectedtextpopup("signInFromMobile");
                togglePopup();
                return;
              }
              signIn()
            }}
          >
            <Twitter />
            Sign In
          </Button>
        )}
        {/* <div className="flex items-center justify-center gap-2 text-theme-black text-lg font-medium p-[10px] border border-gray border-opacity-80 rounded-2xl">
          <ConnectButton chainStatus="none" />
        </div> */}
        <Mode checked={checked} setChecked={setChecked} />
        <Popup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          children={data[selectedtextpopup]}
        />
      </div>
    );
  };
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // return () => {
    //   document.body.style.overflow = "auto";
    // };
  }, [showMenu]);

  return (
    <>
      <div className="sticky top-0 left-0  bg-white z-10">
        <div className="max-w-[1700px] mx-auto flex items-center justify-between pl-[42px] pr-[50px] h-20">
          <Link to="/">
            <Logo className="!text-primary font-semibold" />
          </Link>
          <div className=" hidden lg:block">
            <HeaderRightContainer />
          </div>
          <div
            className="block lg:hidden w-5 transition-all"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            {showMenu ? <MenuCross /> : <Hamburger />}
          </div>
          <div
            className="fixed bg-[#F6F7FB] top-[80px] pt-[36px] z-10 h-[calc(100vh-16px)] w-full block lg:hidden transition-all duration-500"
            style={{ left: showMenu ? 0 : "-100%" }}
          >
            <HeaderRightContainer />
          </div>
        </div>
      </div>
    </>
  );
}
