import Terms from 'components/Terms';
import React from 'react'

const Termss = ({setSelectedTextPopup,setIsChecked}) => {
  return (
    <div>
        <div>
        <p className="flex justify-center items-center text-2xl md:text-[28px] font-[500] opacity-90 pt-0 md:pt-2 pb-6">
          Terms of Service Agreement
        </p>
        <div className="max-h-[250px] md:max-h-[395px] h-[100%] overflow-y-auto custom-scroll">
          <Terms />
        </div>
        <div className="flex px-0 md:px-6 justify-between items-center sticky bottom-0 pt-8 pb-3 bg-white w-full">
          <button 
            onClick={() => setSelectedTextPopup("boost")}
            className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-6 md:px-10 py-2 text-[18px] font-[600]"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (localStorage.getItem("UserName")) {
                setIsChecked(true);
                setSelectedTextPopup("payment");
              } else {
                setIsChecked(true);
                setSelectedTextPopup("askForTwitter");
              }
            }}
            className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-10 md:px-16 py-2 text-[18px] font-[600]"
          >
            Agree
          </button>
        </div>
      </div>
    </div>
  )
}

export default Termss