import React from 'react'

const SmallCircle = ({isboostActive}) => {
  
  return (
    <div>
          {
            isboostActive === true || false ?
           
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle
                cx="4"
                cy="4"
                r="4"
                fill={isboostActive ? "#11D885" : "red"}
              />
            </svg> :
             <svg
             xmlns="http://www.w3.org/2000/svg"
             width="8"
             height="8"
             viewBox="0 0 8 8"
             fill="none"
           >
             <circle cx="4" cy="4" r="4"  fill={isboostActive ? "#11D885" : "red"} />
           </svg>
           
          }
    </div>
  )
}

export default SmallCircle