export default function MenuCross() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16.4515L16.4515 1.00002"
        stroke="#02071A"
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 1L16.5081 16.5081"
        stroke="#02071A"
        stroke-width="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
