import { useState, createContext, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { fetchDetails } from "api/user";
import { logout } from "../firebase";

const UserContext = createContext(null);

export function useUser() {
  return useContext(UserContext);
}

export default function NFTProvider({ children }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { address } = useAccount();
  const [tempAddress, setTempAddress] = useState();
  const [user, setUser] = useState();
  const { disconnect } = useDisconnect({
    onSuccess(data) {
      navigate("/verify");
    },
  });
  useEffect(() => {
    if (address) {
      localStorage.setItem("userWalletAddress", address);
      if (tempAddress && tempAddress !== address) {
        logout();
        disconnect();
      }
      setTempAddress(address);
      if (state?.user) {
        setUser(state.user);
        return;
      }
      
      fetchDetails(address).then((data) => {
        if (data) {
          setUser(data);
        } else {
          navigate("/connect");
        }
      });
    }
  }, [address]);

  const clearUser = () => {
    setUser([]);
  };

  const refetch = () => {
    fetchDetails(address).then((data) => {
      if (data) {
        setUser(data);
      } else {
        navigate("/connect");
      }
    });
  };

  return (
    <UserContext.Provider value={{ user, clearUser, refetch }}>
      {children}
    </UserContext.Provider>
  );
}
