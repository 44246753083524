import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  handleAddUser,
  handleMonth,
  handleCheckboxChange,
} from "../../utils/index";
import axios from "axios";
import { paymentMonths } from "Constant";
import { getTransactionsByWalletAddress } from "api/getTransactionsByMonth";

const month = paymentMonths;

const AskForTwtter = ({
  setSelectedMonth,
  loading,
  checkout,
  userDetails,
  setSelectedTextPopup,
  isChecked,
  setIsChecked,
  setTwitterName,
  twitterName,
  setpaid,
}) => {
  const [buttonid, setButtonId] = useState("");
  const [isCurrentMonthPaymentStatus, setCurrentMonthPaymentStatus] = useState(false);
  const [isPreviousMonthPaymentStatus, setPreviousMonthPaymentStatus] = useState(false);
  const [paidMonths, setPaidMonths] = useState([]);
  const currentMonth = new Date().getMonth() + 1;

  const isMonthPaid = (monthId) => {
    const currentYear = new Date().getFullYear();
    return paidMonths.some(({ year, month }) => year === currentYear && month === monthId);
  };
  

  // this is to get the month response of the transactions
  const fetchData = async () => {
    try {
     
      
      // axios.post(
      //   `${process.env.REACT_APP_PUBLIC_URL}/transactions/get-transactionsmonth-by-walletAddress`,
      //   {
      //     walletAddress: userDetails?.walletAddress,
      //   }
      // );
      // Assume the response from the API is stored in a variable named 'response'
      const responseMonths = await getTransactionsByWalletAddress(userDetails?.walletAddress) // Format: ['YYYY-MM', 'YYYY-MM', ...]

      // console.log("responseMonths",responseMonths)
      // Get current year and month
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      // Convert the response months into a more usable format
      const parsedMonths = responseMonths.map(monthStr => {
        const [year, month] = monthStr.split('-').map(Number);
        return { year, month };
      });
      
      setPaidMonths(parsedMonths);

      // Check if current month and previous month are in the response
      const isCurrentMonthPaid = parsedMonths.some(({ year, month }) => year === currentYear && month === currentMonth);
      const isPreviousMonthPaid = (() => {
        const previousMonth = currentMonth - 1 === 0 ? 12 : currentMonth - 1;
        const yearOfPreviousMonth = currentMonth - 1 === 0 ? currentYear - 1 : currentYear;
        return parsedMonths.some(({ year, month }) => year === yearOfPreviousMonth && month === previousMonth);
      })()

      // You can now use isCurrentMonthPaid and isPreviousMonthPaid in your frontend logic
      // console.log(`Is current month paid? ${isCurrentMonthPaid}`);
      // console.log(`Is previous month paid? ${isPreviousMonthPaid}`);

      setPreviousMonthPaymentStatus(isPreviousMonthPaid)
      setCurrentMonthPaymentStatus(isCurrentMonthPaid)
      setpaid(!isCurrentMonthPaid)

      // if (!isCurrentMonthPaid) {

      //   setpaid(false);
      // } else {
      //   setCurrentMonthPaymentStatus("paid");
      //   setpaid(true);
      // }
      // console.log("Is current Month Paid",isCurrentMonthPaymentStatus)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentMonth]);

  return (
    <div>
      <div className="px-2 md:px-6 flex flex-col justify-center items-center">
        {userDetails?.twitter === "undefined" || "" ? (
          <h1 className="text-center text-primary text-xl md:text-3xl font-extrabold uppercase max-w-md">
            Pay to become a boosted member of 627eea!
          </h1>
        ) : (
          <h1 className="text-center text-primary text-xl md:text-3xl font-extrabold uppercase max-w-md">
            Pay to continue your boosted membership
          </h1>
        )}
        <h2 className="text-center text-slate-950 text-4xl md:text-5xl font-semibold my-6 capitalize">
          $60
        </h2>
        <img src="./boostbg.svg" alt="" srcset="" />

        {
          // show hide twitter input fields
          userDetails?.twitter === "undefined" || "" ? (
            <div>
              <h5 className=" text-slate-950 text-xl font-semibold mt-5 mb-1">
                Contact Information
              </h5>
              <input
                type="text"
                placeholder="Type your Twitter username... (include the @)"
                value={twitterName}
                onChange={(e) => {
                  setTwitterName(e.target.value);
                }}
                onBlur={(e) => {
                  handleAddUser(e.target.value);
                }}
                className="w-full border border-[#7F7F7F] rounded-md px-4 py-2 mt-4 text-xs md:text-base"
              />
            </div>
          ) : (
            ""
          )
        }
        {/* Month */}
        {/* <div>
          <p className="text-slate-950 text-xl text-center font-semibold mt-5 mb-1">
            Month
          </p>
          <div className="flex flex-wrap">
            {month.map((item) => {
              // console.log('month.map->currentMonth',currentMonth)
              const isButtonDisabled = item.id < currentMonth ;
              const isMonthPayable =
                (currentMonth === 12 && item.id === 1) ||
                (currentMonth !== 12 && item.id === (currentMonth % 12) + 1 );
              const isCurrentMonthMaxPaymentDateInitiated = new Date().getDate()>28;
              const isCurrentMonthMaxPaymentDateEnded=new Date().getDate()<15;
              // const 
              // console.log('currentMonth->',currentMonth)
              // console.log("isButtonDisabled",item,isButtonDisabled)
              // console.log('previous Month',isPreviousMonthPaymentStatus)
              // console.log('currentMonth',isCurrentMonthPaymentStatus)
              // console.log('isMOnthPayable',isMonthPayable)

              return (
                <div key={item.id}>
                  <button
                    value={item.value}
                    onClick={(e) =>
                      handleMonth(
                        e.target.value,
                        item.id,
                        setSelectedMonth,
                        setButtonId
                      )
                    }
                    className={`px-2 mx-2 rounded-lg my-2 border-[1px] font-semibold py-2 text-white ${item.id === 1 ? "bg-red-500 text-white":"bg-slate-400"}`}
                  //   className={`h-[2.58rem] 
                  //   px-2 mx-2 rounded-lg my-2 border-[1px]
                  //   ${
                  //     isMonthPayable
                  //       ? `${
                  //           isCurrentMonthMaxPaymentDateInitiated
                  //             ? "bg-red-500 text-white"
                  //             : "border-primary text-primary"
                  //         } `
                  //       : isButtonDisabled
                  //       ? "text-gray cursor-not-allowed"
                  //       : !isCurrentMonthPaymentStatus
                  //       ? "bg-slate-500 text-white cursor-not-allowed"
                  //       : isCurrentMonthPaymentStatus
                  //       ? "bg-white text-green-500 border-[2px] border-green-500"
                  //       : ""
                  //   }
                  //   ${buttonid == item.id ? "bg-primary text-white" : ``}
                  //  }  border-opacity-50`}
                    disabled={item.id!==1}
                  >
                    {item.value}
                  </button>
                </div>
              );
            })}
          </div>
        </div> */}
        <div>
      {/* Month */}
        <p className="text-slate-950 text-xl text-center font-semibold mt-5 mb-1">
          Month
        </p>
        <div className="flex flex-wrap">
          {month.map((item) => {
            const today = new Date();
            const isCurrentMonth = item.id === currentMonth;
            const isNextMonth = item.id === ((currentMonth % 12) + 1);
            const isMonthPaidStatus = isMonthPaid(item.id);
            let buttonColorClass = "";
             if (isMonthPaidStatus ) {
              buttonColorClass = "bg-green-500"; // Green if month is paid
            }
            else if (isCurrentMonth && today.getDate() > 14) {
              buttonColorClass = "bg-slate-500"; // Gray for current month if date > 14
            } else if (isNextMonth && !isMonthPaidStatus) {
              if (today.getDate() > 28 || today.getDate() < 15) {
                buttonColorClass = "bg-red-500"; // Red for next month if date > 28 or < 15 and unpaid
              } else if (today.getDate() >= 14 && today.getDate() <= 28) {
                buttonColorClass = "bg-primary"; // Purple for next month if date between 14 & 28 and unpaid
              }
            } else{
              buttonColorClass = "bg-slate-300"
            }

            return (
              <div key={item.id}>
                <button
                  value={item.value}
                  onClick={(e) =>
                    handleMonth(
                      e.target.value,
                      item.id,
                      setSelectedMonth,
                      setButtonId
                    )
                  }
                  className={`px-2 mx-2 rounded-lg my-2 border-[1px] font-semibold   py-2 text-white ${buttonColorClass}`}
                  disabled={!isMonthPaidStatus && !isCurrentMonth && !isNextMonth}
                >
                  {item.value}
                </button>
              </div>
            );
          })}
        </div>
      </div>
        <div class="flex items-center mt-4 mb-6 md:mb-8">
          <input
            id="default-checkbox"
            type="checkbox"
            onChange={(e) => handleCheckboxChange(e, setIsChecked)}
            checked={isChecked}
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label class="ml-2 text-[#02071A] text-base md:text-[18px] font-[600] ">
            I Agree to all{" "}
            <span
              onClick={() => {
                setSelectedTextPopup("terms");
              }}
              className="text-[#627EEA] cursor-pointer text-[18px] font-[600]"
            >
              terms & conditions
            </span>
          </label>
        </div>
        {userDetails.twitter !== "" ? (
          <button
            onClick={() => checkout()}
            className="rounded-[15px] disabled:bg-slate-400 disabled:border-slate-400  bg-primary  border-[2.5px] border-primary  mb-5 text-[#FFF] px-[62px] py-[14px] text-[18px] font-[600]"
          disabled={ loading || !isChecked}
          >
            {loading ? "Please Wait..." : "Pay Now"}
          </button>
        ) : (
          <button
            onClick={() => {
              if (twitterName.trim() === "" || !twitterName.startsWith("@")) {
                toast.error("Please enter a valid twitter username!", {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                checkout();
              }
            }}
            disabled={
               !twitterName || loading || !isChecked
            }
            className="rounded-[15px] disabled:bg-slate-400 disabled:border-slate-400  bg-primary  border-[2.5px] border-primary  mb-5 text-[#FFF] px-[62px] py-[14px] text-[18px] font-[600]"
          >
            {loading ? "Please Wait..." : "Pay Now"}
          </button>
        )}
      </div>
    </div>
  );
};

export default AskForTwtter;
