import React from "react";

function Moon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
    >
      <g filter="url(#filter0_d_2157_6139)">
        <circle cx="32" cy="34" r="18" fill="white" />
      </g>
      <path d="M31.8164 23.0615V19.6" stroke="#627EEA" strokeLinecap="round" />
      <path
        d="M37.1191 24.9072L37.8114 23.7081"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M40.0312 29.3613L43.029 27.6306"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M43.7676 34.1108H42.383"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M40.2363 38.998L43.2341 40.7288"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M37.8125 44.3843L37.0183 43.2501"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M31.791 48.5386V45.077"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M25.7676 44.5229L26.4599 43.3238"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M20.3789 40.7158L23.3767 38.9851"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M21.1973 34.1108H19.8127"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M23.3438 29.2231L20.346 27.4924"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <path
        d="M26.5996 24.9995L25.8054 23.8653"
        stroke="#627EEA"
        strokeLinecap="round"
      />
      <circle
        cx="31.7202"
        cy="33.7227"
        r="7.53077"
        fill="#627EEA"
        stroke="#627EEA"
      />
      <defs>
        <filter
          id="filter0_d_2157_6139"
          x="0"
          y="0"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2157_6139"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2157_6139"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Moon;
