import Lottie from "lottie-react";
import boxFile from "./box.json";

export default function Box({ autoplay = true, speed = 150, loop = false }) {
  return (
    <Lottie
      autoplay={autoplay}
      speed={speed}
      loop={loop}
      animationData={boxFile}
    />
  );
}
