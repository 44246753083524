import { useUser } from "context/user";
import { useEffect } from "react";
import { useMemo, useState } from "react";
import { Cross } from "recharts";

export default function PaymentBanner({paid}) {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
  });

  const startDate = new Date();
  startDate.setDate(21);

  const endDate = new Date();
  endDate.setDate(28);

  useEffect(() => {
    const currentDate = new Date();
    if (currentDate >= startDate && currentDate <= endDate) {
      const countdownInterval = setInterval(updateCountdown, 1000);

      return () => clearInterval(countdownInterval);
    } else {
      setCountdown(null);
    }
  }, [startDate, endDate]);

  const updateCountdown = () => {
    const currentDate = new Date();
    const timeDifference = endDate - currentDate;

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      setCountdown({
        days,
        hours,
      });
    } else {
      setCountdown(null);
    }
  };
 
  return (
    <div>
      {countdown && paid === false ?
      <div>
      <div className="bg-[#ff0100] z-50 relative w-full flex items-center justify-center text-white py-2">
      Pay for next month boost before 28th,&nbsp;  <span className="font-semibold"> {`${countdown.days} days, ${countdown.hours} hours `} </span> &nbsp;remaining.
    </div>
     
        
          
          </div>
         : (
          "" 
        )}
      
     
    </div>
  );
}
