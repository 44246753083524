import React from "react";

function ForgotPassword() {
  return (
    <div className="flex flex-col px-9 h-screen md:w-[602px] mx-auto">
      <div className="my-4 mt-[24px] mb-[138px]">
        <p className="text-white text-[32px] flex items-center justify-center font-[700]">
          627EEA
        </p>
      </div>
      <div className="rounded-2xl bg-white shadow-xl md:p-[39px] p-6">
        <p className="text-[#02071A] flex justify-center items-center text-[40px] mb-6 font-[600]">
          Forgot Password?
        </p>
        <div>
          <div className="space-y-[8px]">
            <label class="text-[16px]text-[#02071A] font-[500]">Email</label>
            <input
              type="text"
              id="first_name"
              style={{ borderColor: "rgba(2, 7, 26, 0.08)" }}
              class="block border w-full p-[15px] rounded-[8px] px-[14px] text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
              placeholder="hello@hereispurple.com"
              required
            />
          </div>{" "}
          <button className="bg-[#627EEA] mb-6 text-white text-[18px] font-[600] mt-[38px] rounded-[8px] w-full p-[15px]">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
