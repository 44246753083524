import { IndexPage,  Verify,  Dashboard } from "pages";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import ForgotPassword from "pages/ForgotPassword";
import CheckEmail from "pages/CheckEmail";
import ChangePassword from "pages/ChangePassword";
import AdminDashboard from "pages/AdminDashboard";
import LandingPage from "pages/LandingPage";
import Faqs from "pages/Faqs";
import DonNotHaveNFT from "pages/DonNotHaveNFT";
import PurpleDex from "pages/PurpleDex";
import { useState } from "react";

function App() {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem('authToken')||"");
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/connect" element={<IndexPage />} />
      <Route path="/purpledex" element={<PurpleDex />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/FAQS" element={<Faqs />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/do-not-have-nft" element={<DonNotHaveNFT />} />
      <Route
        path="/admin/login"
        element={<LoginPage setAuthenticated={setAuthenticated} />}
      />
      <Route path="/admin/forgtpassword" element={<ForgotPassword />} />
      <Route path="/admin/CheckEmail" element={<CheckEmail />} />
      <Route path="/admin/ChangePassword" element={<ChangePassword />} />
      <Route
        path={authenticated !== "" ? "/admin/AdminDashboard" : "/admin/login"}
        element={authenticated !== "" ? <AdminDashboard /> : <LoginPage />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
