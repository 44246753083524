import { Boxloader } from "common";
import React from "react";

const Pending = () => {
  return (
    <div>
      <div className="px-6 overflow-y-hidden max-w-lg">
        <div className="flex justify-center items-center ">
          <Boxloader />
        </div>
        <div className="flex flex-col mb-5 justify-center items-center w-full">
          <p className="text-[#02071A] text-sm md:text-3xl font-[600] pt-16 pb-10 text-center">
            Transaction pending confirmed. Please allow 5-10 minutes for
            confirmation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pending;
