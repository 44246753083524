import BlackCat from "./assets/images/animal/black cat.png";
import BlackChicken from "./assets/images/animal/black chicken.png";
import BlackCroc from "./assets/images/animal/black croc.png";
import BlackDragon from "./assets/images/animal/black dragon.png";
import BlackShark from "./assets/images/animal/black shark.png";
export const BreedImages =[
    {
        namee:"cat",
        image:BlackCat,
    },
    {
        namee:"shark",
        image:BlackShark,
    },
    {
        namee:"drag",
        image:BlackDragon,
    },
    {
        namee:"chick",
        image:BlackChicken,
    },
    {
        namee:"Croc",
        image:BlackCroc,
    },
]

export const paymentMonths = [
    {
      id: 1,
      value: "January",
    },
    {
      id: 2,
      value: "February",
    },
    {
      id: 3,
      value: "March",
    },
    {
      id: 4,
      value: "April",
    },
    {
      id: 5,
      value: "May",
    },
    {
      id: 6,
      value: "June",
    },
    {
      id: 7,
      value: "July",
    },
    {
      id: 8,
      value: "August",
    },
    {
      id: 9,
      value: "September",
    },
    {
      id: 10,
      value: "October",
    },
    {
      id: 11,
      value: "November",
    },
    {
      id: 12,
      value: "December",
    },
  ];
  