import { signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  TwitterAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSingSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const twitterProvider = new TwitterAuthProvider();

const signIn = () => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  if (isMobile) {
    signInWithRedirect(auth, twitterProvider);
  } else {
    signInWithPopup(auth, twitterProvider)
      .then((result) => {
        localStorage.setItem(
          "UserName",
          JSON.stringify(result.user.reloadUserInfo)
        );
        window.location.reload();
      })
      .catch((error) => {
        // An error occurred during Twitter login
        console.error(error);
      });
  }
};

getRedirectResult(auth)
  .then((result) => {
    if (result?.user?.reloadUserInfo) {
      localStorage.setItem(
        "UserName",
        JSON.stringify(result?.user?.reloadUserInfo)
      );
      window.location.reload();
    }
    throw new Error("No user found");
  })
  .catch((error) => {
    // An error occurred during Twitter login
    console.error(error);
  });

const logout = async () => {
  localStorage.removeItem("UserName");
  signOut(auth)
    .then(() => {})
    .catch((error) => {
      // An error happened.
    });
  window.location.reload();
};

const unsubscribe = auth?.onAuthStateChanged((user) => {
  if (user) {
    // User is logged in
  } else {
    // User is logged out
    // localStorage.removeItem("UserName");
  }
});

export { signIn, logout, auth, unsubscribe };
