import React,{useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";



import axios from "axios"
function LoginPage({ setAuthenticated }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading,setLoading]= useState(false)

  

  const handleSignIn = async () => {
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/admin/login`, { email, password });
      
      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.setItem('authToken', response?.data?.token);
      setLoading(false);
      setAuthenticated(response?.data?.token);
      navigate("/admin/AdminDashboard");
    } catch (error) {
      toast.error(error.response.data.error);
      setLoading(false)
    }
    const authToken = localStorage.getItem('authToken');
  if (authToken) {
    setAuthenticated(authToken);
  }
  };
  return (
    <div className="h-full w-full bg-primary">
    <div className="flex flex-col px-9 h-screen md:w-[602px] mx-auto">
      <div className="my-4 mt-[24px] mb-[38px]">
        <p className="text-white text-[32px] flex items-center justify-center font-[700]">
          627EEA
        </p>
      </div>
      <div className="rounded-2xl bg-white shadow-xl md:p-[39px] p-6">
        <p className="text-[#02071A] flex justify-center items-center text-[40px] mb-6 font-[600]">Sign In</p>
        <div>
          <div className="space-y-[8px]">
            <label class="text-[16px]text-[#02071A] font-[500]">Email</label>
            <input
              type="text"
              style={{ borderColor: "rgba(2, 7, 26, 0.08)" }}
              class="block border w-full p-[15px] rounded-[8px] px-[14px] text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
              placeholder="hello@hereispurple.com"
              value={email}
        onChange={(e) => setEmail(e.target.value)}

              required
            />
          </div>
          <div className="space-y-[8px] mt-4">
            <label class="text-[16px] text-[#02071A] font-[500]">Password</label>
            <div style={{borderColor: "rgba(2, 7, 26, 0.08)"}} class="flex  border w-full p-[15px] rounded-[8px] px-[14px] focus:border border-blue-500 text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25">
              <input
                type="password"
                class="block border focus:border-none focus:outline-none  w-full  border-none rounded-[8px]  text-gray-900 text-opacity-25 bg-gray-900 bg-opacity-25"
                placeholder="*****************"
                value={password}
        onChange={(e) => setPassword(e.target.value)}
                required
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
          </div>
          <p className="text-[#627EEA] text-[18px] font-[600] text-end mt-[24px] mb-[48px]">Forgot password?</p>
          <button onClick={handleSignIn} className="bg-[#627EEA] mb-6 text-white text-[18px] font-[600] rounded-[8px] w-full p-[15px]">
          {  loading ? "Please wait....":"Login"  }
        </button>
        </div>
       
      </div>
    </div>
    </div>
  );
}

export default LoginPage;
