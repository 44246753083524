import { Animation } from "common";
import PageLoader from "common/PageLoader";
import { useEffect, useState } from "react";
import Header from "sections/Header";
import { motion } from "framer-motion";
import LeftMenu from "sections/LeftMenu";
import NFTProvider from "context/nft";
import UserProvider, { useUser } from "context/user";

import Breed from "components/Breed";
import Eggs from "components/Eggs";
import { twJoin } from "tailwind-merge";
import { useAccount } from "wagmi";
import { purpledex } from "api/purpledex";

export default function PurpleDex() {
  const [checked, setChecked] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );

  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, []);

  const [tab, setTab] = useState(false);

  let collection = tab ? "animal" : "egg";

  const [nftImages, setNftImages] = useState({
    cat: [],
    dragon: [],
    shark: [],
    croc: [],
    chicken: [],
  });
  const address = localStorage.getItem("userWalletAddress");
  useEffect(() => {
    if (address !== "" && address.length && address !== undefined) {
      const fetchData = async () => {
        try {
          const response = await purpledex(address, collection);
          setNftImages({
            cat: response.cats || [],
            dragon: response.dragons || [],
            shark: response.sharks || [],
            croc: response.crocs || [],
            chicken: response.chickens || [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [address, collection]);

  return (
    <>
      <UserProvider>
        <NFTProvider>
          {showLoading ? (
            <PageLoader />
          ) : (
            <motion.div
              className="z-30 overflow-x-hidden"
              initial={{ opacity: 0, rotate: 0 }}
              whileInView={{
                opacity: 1,
              }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
            >
              <Header
                checked={checked}
                setChecked={setChecked}
                rigtbutton="dashboard"
              />
              <div className="flex justify-center items-center ">
                <div className="pt-8 2xl:w-[1440px] pb-9 md:py-9 z-30 gap-x-7 pl-3 md:pl-11  md:pr-5 maindiv overflow-x-hidden w-[100%] justify-center md:items-start items-center flex flex-col md:flex-row align-start gap-y-10 max-w-[1700px] mx-auto">
                  <div className="flex-1 flex justify-center -ml-5 md:ml-0 w-full md:w-fit md:max-w-[303px] pl-2 md:pl-0 z-[5]">
                    <LeftMenu />
                  </div>
                  <div className="flex-1 md:flex-[3_3_0] ml-[-14px] overflow-x-hidden z-[5]  w-full   flex flex-col justify-center items-center">
                    <h2 className="text-white text-[40px] font-semibold text-center mb-6">
                      Purpledex
                    </h2>
                    {/* <div class="container"></div> */}
                    <div className="overflow-hidden">
                      <div className="w-full flex items-center justify-center">
                        <div class="tabs">
                          <input
                            type="radio"
                            id="radio-1"
                            name="tabs"
                            checked={tab ? false : true}
                          />
                          <label
                            onClick={() => setTab(false)}
                            class="tab"
                            for="radio-1"
                          >
                            Eggs
                          </label>
                          <input
                            type="radio"
                            id="radio-2"
                            name="tabs"
                            checked={tab ? true : false}
                          />
                          <label
                            onClick={() => setTab(true)}
                            class="tab"
                            for="radio-2"
                          >
                            Breeds
                          </label>

                          <span class="glider"></span>
                        </div>
                      </div>
                      {tab ? (
                        <div className="mt-10">
                          <Breed nftImages={nftImages} />
                        </div>
                      ) : (
                        <div className="mt-10">
                          <Eggs nftImages={nftImages} />
                        </div>
                      )}
                    </div>
                  </div>
                  <Animation
                    className={`${checked ? "!bg-primary" : "!bg-[#ffffff33]"}`}
                  />
                </div>
              </div>
              {/* <div className="w-screen h-[calc(100dvh-84px)] flex items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-12">
                  <h2
                    className={twJoin(
                      "text-3xl mdt:ext-5xl font-semibold text-center",
                      checked ? "text-primary" : "text-white "
                    )}
                  >
                    Coming Soon...
                  </h2>
                </div>
              </div>
              <Animation
                className={checked ? "!bg-primary" : "!bg-[#ffffff33]"}
              /> */}
            </motion.div>
          )}
        </NFTProvider>
      </UserProvider>
    </>
  );
}
