import React, { useState } from "react";
import { Logo } from "common";
import Transactions from "./Transactions";
function AdminDashboard() {
  const [tabname, setTabName] = useState("");
  const tabchangename = (name) => {
    setTabName(name);
  };
  return (
    <>
      <div className="w-full h-screen bg-primary overflow-hidden">
        <div className="sticky top-0 left-0 w-screen h-24 bg-white flex items-center justify-between px-10 ">
          <Logo className="text-primary font-semibold" />
          <div className="flex items-center gap-x-2">
           
            <p className="text-[#FF154D] px-5 border-[1px] border-[#EBECED]  py-3 rounded-xl mx-2">Logout</p>
          </div>
        </div>
        <div className="flex left-0 mr-12   py-9 overflow-x-hidden">
          {/* <div className="w-[25%] z-50 h-[78vh] rounded-[12px] bg-white ">
            <Adminleft tabchangename={tabchangename} />
          </div> */}
          <div className="w-full h-[76vh] ml-12">
            {/* {tabname === "Users" ? <Adminright /> : */}
             <Transactions />
            
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;
