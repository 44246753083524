
export default function Animation({ className }) {
  return (
    <>
      <div className="top-0 fixed w-screen">
        <ul className="circles transition-all duration-100 ease-in-out">
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
          <li className={className ? className : `!bg-white !bg-opacity-30`}></li>
        </ul>
      </div>
    </>
  );
}
