import React from "react";
import { motion } from "framer-motion";

function OptionCard0() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 200, rotate: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="bg-[#EFEFF3] flex justify-center flex-col items-center w-[396px] h-[400px]  mx-[20px] rounded-[24px]"
    >
      <p class="text-[#02071A] text-[25px] font-[600]">Option 1</p>
      <p class="text-[#02071A] text-center mt-[14px] mb-[53px] text-[14px] px-7 font-[400]">
      Stake your square for 30 days to evolve for free.
      </p>

      <div>
        <img
          className="rounded-[24px] w-[160px] h-[160px]"
          src="./eaggig.gif"
          alt=""
          srcset=""
        />
      </div>
      {/* <p class="text-[#02071A] mt-[12px] text-[14px] font-[400]">
      and evolve your NFT for free.
      </p> */}
    </motion.div>
  );
}

export default OptionCard0;
