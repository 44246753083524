import axios from "axios";
import { Cross } from "icons";
import Download from "icons/Download";
import { useEffect, useState } from "react";
// import { useContractWrite, usePrepareContractWrite, useAccount } from 'wagmi';
import { useUser } from "context/user";
import abi from "../../artifacts/627EEA/abi.json";

import { toast } from "react-toastify";
import { useContractWrite, useWaitForTransaction } from "wagmi";
import * as ReactDOM from "react-dom";
import { useRef } from "react";

const NftGiffDownload = ({ imageUrl, label, setDownloadGiffPopup }) => {
  const { user: userDetails } = useUser();
  const [burnedState, setBurnedState] = useState("");

  // const downloadLink =
  //   'https://d17ilmv9oq9gd3.cloudfront.net/phase-1-gifs/38.gif?Expires=1737202533&Key-Pair-Id=K2Z17UHT2CNG82&Signature=D2ic-xhGeEoQCDfxb75phNhGyQDZV~TdDbpwvN60yp0aTAxuruZj8v4tjgvf4HhTVosb0BVHWXC7mhXJCRrixIurSHyiPQGi04Z29mV3~nlMegSuS0VPFTeZGT~Ve1N1XyOYwzF5hPyPtixViKgGSruNF~edU0cD054NHiPtQtGpUyVzF6pUoGY2YKvBhleZP2~CH3dcGf42ByA24x8LTcNo2wGJbCRk9RWmYT1fMxC1fw9ueq6M5jASk5uf8AboIZWjf292l0Oaa2spgSBkiomGWrkMh2Baik3GiCJcv-hTrHIk9M5mX8vrdHz~jsGUt7XqmqbXNJDkA-jNh9aIFQ__';

  // console.log({ imageUrl, label, setDownloadGiffPopup },`627EEA #${label}`)

  // for burn logic
  const burnNFT = async () => {
    burnWrite({
      args: [
        [label],
        ["0x0000000000000000000000000000000000000000000000000000000000000000"],
      ],
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/nft/burn`,
        {
          nftId: label,
          // userId: userDetails.walletAddress,
          userId: "0x10eD0A60087DAcC033bF2563302b1849F9Fa7d4A",
        }
      );

      return response.data.collection;
    } catch (error) {
      throw new Error(error);
    }

    // web3 burn logic
    // const { address } = useAccount();

    // const { config } = usePrepareContractWrite({
    //   addressOrName: 'YOUR_CONTRACT_ADDRESS', // Replace with your contract address
    //   contractInterface: ['function safeTransferFrom(address from, address to, uint256 tokenId)'], // Replace with your contract's ABI
    //   functionName: 'safeTransferFrom',
    //   args: [address, '0x000000000000000000000000000000000000dEaD', label], // Assuming label is your tokenId
    // });

    // const { write } = useContractWrite(config);

    // database update burn logic
  };

  const handleBurnNFT = async () => {
    try {
      // Assume you have a function to burn the NFT and wait for its completion
      await burnNFT();

      // Define the contract address and ABI
      const contractAddress = "YOUR_CONTRACT_ADDRESS";
      const contractABI = [
        // Simplified ABI for ERC-721 safeTransferFrom function
        "function safeTransferFrom(address from, address to, uint256 tokenId)",
      ];

      alert("Nft Burned Successfully");
      // If the burn was successful and you want to refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error burning NFT:", error);
      // Handle any errors, perhaps notify the user
    }
  };

  // useEffect(() => {
  //   fetchgiff();
  // }, [label]);

  // const CONTRACT_ADDRESS = "0xf623979c00EbF2af783466c79eAAF38d1901D75e"; // sepolia test-net address
  const CONTRACT_ADDRESS = "0x21Baf34dbDf520c47a2F45228c4c3d7Fa7508777";
  const {
    data: burnData,
    isLoading: burnIsLoading,
    isSuccess: burnIsSuccess,
    error: burnError,
    write: burnWrite,
  } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: abi,
    functionName: "Burn",
  });

  const {
    data: batchBurnTxnData,
    error: burnTxnError,
    isLoading: burnTxnConfirming,
    isSuccess: burnTxnConfirmed,
  } = useWaitForTransaction({
    hash: burnData?.hash,
  });

  useEffect(() => {
    if (!burnError) return;
    console.log("error", burnError);
    // toast.error(
    //   burnError.cause?.reason || burnError.shortMessage || "Burn Failed",
    //   {
    //     position: toast.POSITION.TOP_CENTER,
    //   }
    // );
    setBurnedState("error");
  }, [burnError]);

  useEffect(() => {
    if (!burnTxnConfirmed) return;
    console.log("success");
    // toast.success("Successfully Burned", {
    //   position: toast.POSITION.TOP_CENTER,
    // });
    setBurnedState("success");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }, [burnTxnConfirmed]);

  useEffect(() => {
    if (!burnIsSuccess) return;
    console.log("success");
    toast.info("Burn transaction is being confirmed. Please wait...", {
      position: toast.POSITION.TOP_CENTER,
    });
  }, [burnIsSuccess]);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
      {burnedState === "success" ? (
        <div className="bg-white max-w-[586px] lg:w-[586px] lg:h-[310px] rounded-3xl p-6 flex justify-center items-center flex-col">
          <div>
            <img height={100} width={100} src="/flame.gif" alt="flame" />{" "}
          </div>

          <h1 className="text-3xl font-bold mb-2 my-12">Success!</h1>
          <p className="mt-4 text-md">
            Your Square has been successfully burned.
          </p>
        </div>
      ) : burnedState === "error" ? (
        <div className="bg-white max-w-[586px] lg:w-[586px] lg:h-[310px] rounded-3xl p-6 flex justify-center items-center flex-col">
          <div
            className="w-full flex mb-2  justify-end cursor-pointer"
            onClick={() => setDownloadGiffPopup(false)}
          >
            <Cross />
          </div>
          <div className="">
            <div className="absolute ">
              <img
                height={100}
                width={100}
                src="/flame.gif"
                alt="txn-success"
              />
            </div>
            <div className="relative">
              <img
                height={100}
                width={100}
                src="/burn-failed-icon.png"
                alt="txn-failed"
              />
            </div>
          </div>
          {/* <h1 className="text-xl font-bold mb-2">Success!</h1>
        <p>Your Square has been successfully burned.</p> */}
          <h1 className="text-3xl font-bold mb-2 my-12">
            Something went wrong!
          </h1>
          <p className="mt-4 text-md">We are unable to process your Request.</p>
        </div>
      ) : (
        <div className="bg-white max-w-[400px] lg:w-[260px] lg:h-[290px] rounded-2xl px-6 pt-4 flex justify-center items-center flex-col">
          {/* <div
            className="w-full flex mb-2  justify-end cursor-pointer"
            onClick={() => setDownloadGiffPopup(false)}
          >
            <Cross />
          </div> */}

          {/* <img src="/flame.gif" alt="flame" /> */}
          <div className="w-[200px] h-[160px] sm:h-[180px] bg-primary rounded-[16px]">
            {burnIsLoading || burnTxnConfirming ? (
              <div className="absolute z-20 inset-0 flex items-center justify-center">
                <img
                  src={"/animation-burn.gif"}
                  alt={label}
                  className="absolute w-[200px] h-[110px] sm:h-[180px] rounded-[16px] md:mb-12 sm:mb-2"
                />
              </div>
            ) : (
              <></>
            )}
            <img
              className={`w-[300px] h-full object-cover rounded-[16px] transition-all ease-in-out duration-500 transform-gpu ${
                burnIsLoading || burnTxnConfirming ? "grayscale" : ""
              }`}
              src={imageUrl}
              alt="giff"
            />
          </div>

          <div className="flex mt-4 mb-4">
            <button
              className="px-4 py-2 mx-2 rounded-[8px] text-[15px] flex-1 border-2 border-primary bg-white text-primary"
              onClick={() => setDownloadGiffPopup(false)}
            >
              Cancel
            </button>

            <button
              className="px-4 py-2 mx-2  w-24 rounded-lg  bg-primary text-white items-center"
              onClick={handleBurnNFT}
            >
              Burn
            </button>
          </div>
        </div>
      )}
    </div>,
    document.getElementById("modal-root")
  );
};

export default NftGiffDownload;
