import { twMerge } from "tailwind-merge";

export default function Button({ className = "", children, ...rest }) {
  return (
    <>
      <button
        className={twMerge(
          "flex items-center font-poppins justify-center gap-2 text-theme-black text-md font-medium p-3 border border-gray border-opacity-80 active:scale-[0.8] transition-all duration-150 ease-in-out",
          className
        )}
        {...rest}
      >
        {children}
      </button>
    </>
  );
}
