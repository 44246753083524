import { Loader, Animation } from "common";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { fetchDetails } from "api/user";
import { getNFT } from "api/nft";

export default function Verify() {
  const location = useLocation();
  const navigate = useNavigate();
  const { address } = useAccount();
  const [nfts, setNfts] = useState([]);
  const [isFound, setisFound] = useState("loading");
  const [user, setUser] = useState();

  useEffect(() => {
    if (!address) return;
    fetchDetails(address).then((res) => {
      if (res) {
        setUser(res);
        setTimeout(() => {
          getNFT(address).then((data) => {
            if (data.statusCode === 200) {
              if (data.data.length === 0) {
                navigate("/do-not-have-nft");
              }
              setNfts(data.data);
            } else {
              setisFound("forbidden");
            }
          });
        }, 1500);
      } else {
        setisFound("notFound");
      }
    });
  }, [address]);

  useEffect(() => {
    if (nfts.length > 0 && user) {
      navigate("/dashboard", { state: { nfts, user } });
    }
  });

  return (
    <div className="bg-primary h-screen w-screen mt-0">
      <Link to="/">
        <p className="text-white flex justify-center items-center w-full text-3xl font-bold pt-12">
          627EEA
        </p>
      </Link>
      <div className="h-[calc(100dvh-84px)] w-screen flex items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-12">
          {isFound === "loading" ? (
            <>
              <Loader />
              <p className="text-white text-3xl md:text-5xl leading-10 md:leading-[60px] text-center max-w-lg">
                Verifying that you hold a{" "}
                <span className="font-semibold">#627EEA</span> NFT
              </p>
            </>
          ) : isFound === "notFound" ? (
            <p className="text-white text-3xl md:text-5xl leading-10 md:leading-[56px] text-center">
              You do not hold a <span className="font-semibold">#627EEA</span>{" "}
              NFT
            </p>
          ) : isFound === "forbidden" ? (
            <p className="text-white text-3xl md:text-5xl leading-10 md:leading-[56px] text-center">
              Sorry! We are not able to verify your NFT collection
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Animation />
    </div>
  );
}
