import { TwitterTick } from 'icons';
import React from 'react'
import { handleCheckboxChange } from 'sections/LeftMenu/utils';

const Payment = ({setSelectedTextPopup,checkout,loading,userDetails,username,isChecked,setIsChecked}) => {
  return (
    <div><div className="px-0 md:px-6 flex flex-col mt-0 md:mt-2 justify-center items-center">
    <div className="space-y-[24px]">
      <div className="flex gap-y-2 flex-col justify-center items-center">
        <h2 className="text-center text-primary text-3xl font-extrabold font-poppins capitalize w-full max-w-[310px] md:max-w-[450px]">
          PAY TO BECOME A BOOSTED MEMBER OF 627EEA!
        </h2>
        <p className="text-[#02071A] text-4xl md:text-[42px] py-3 font-[600]">
          $60
        </p>
      </div>
    </div>
    <div className="">
      <img src="./boostbg.svg" alt="" srcset="" />
      <div className="mt-[-52px] w-full flex items-center justify-center">
        <div className="flex flex-col gap-4 items-center justify-start">
          <div className="border-4 border-white w-[97px] h-[97px] rounded-full">
            {username ? (
              <img
                className="object-cover w-full h-full rounded-full border-4 border-primary"
                src={username?.providerUserInfo?.[0]?.photoUrl}
                alt=""
                srcset=""
              />
            ) : (
              <img
                src={
                  userDetails?.profileImageUrl ??
                  require("assets/images/avatar.png")
                }
                alt="avatar"
                className="object-cover w-full h-full rounded-full border-4 border-primary"
              />
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center gap-[6px] justify-center">
              <p className="text-lg font-medium text-theme-gray">
                {!username && userDetails?.username}
                {username && username.displayName}
              </p>
              {username && <TwitterTick />}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center mt-4 mb-6 md:mb-8">
      <input
        id="default-checkbox"
        type="checkbox"
        onChange={handleCheckboxChange(setIsChecked)}
        checked={isChecked}
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label class="ml-2 text-[#02071A] text-base md:text-[18px] font-[600]">
        I Agree to all{" "}
        <span
          onClick={() => {
            setSelectedTextPopup("terms");
          }}
          className="text-[#627EEA] cursor-pointer text-[18px] font-[600]"
        >
          terms & conditions
        </span>
      </label>
    </div>
    <button
      onClick={() => checkout()}
      disabled={loading}
      className="rounded-[15px]  bg-primary  border-[2.5px] border-primary  mb-5 text-[#FFF] px-[62px] py-[14px] text-[18px] font-[600]"
    >
      {loading ? "Please Wait..." : "Pay Now"}
    </button>
  </div></div>
  )
}

export default Payment