import React from "react";

function Discord() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clip-path="url(#clip0_2157_4387)">
        <path
          d="M20.6145 4.66807C19.1193 3.97333 17.5374 3.47969 15.9108 3.2002C15.7081 3.56396 15.4714 4.05322 15.3082 4.44245C13.5543 4.18058 11.8167 4.18058 10.0951 4.44245C9.93191 4.05331 9.68984 3.56396 9.48535 3.2002C7.85711 3.47982 6.2739 3.97472 4.77795 4.6717C1.80113 9.13882 0.994133 13.4949 1.39759 17.7893C3.37201 19.2534 5.28543 20.1429 7.16661 20.7249C7.63413 20.0865 8.04741 19.4105 8.40217 18.7041C7.72675 18.4488 7.07561 18.1341 6.45651 17.7638C6.61944 17.6439 6.77852 17.5189 6.93353 17.3891C10.685 19.1316 14.7612 19.1316 18.468 17.3891C18.6237 17.5181 18.7827 17.643 18.9449 17.7638C18.3249 18.135 17.6725 18.4504 16.9957 18.706C17.3525 19.4153 17.765 20.0919 18.2313 20.7267C20.1142 20.1448 22.0294 19.2553 24.0039 17.7893C24.4773 12.811 23.1952 8.49497 20.6145 4.66807ZM8.9133 15.1483C7.78711 15.1483 6.86353 14.1042 6.86353 12.8329C6.86353 11.5615 7.76742 10.5157 8.9133 10.5157C10.0593 10.5157 10.9828 11.5597 10.9631 12.8329C10.9649 14.1042 10.0593 15.1483 8.9133 15.1483ZM16.4882 15.1483C15.362 15.1483 14.4385 14.1042 14.4385 12.8329C14.4385 11.5615 15.3423 10.5157 16.4882 10.5157C17.6341 10.5157 18.5576 11.5597 18.5379 12.8329C18.5379 14.1042 17.6341 15.1483 16.4882 15.1483Z"
          fill="#5865F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2157_4387">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Discord;
