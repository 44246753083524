import { useEffect, useState, memo, useRef } from "react";
import { Button } from "common";
import { TwitterTick } from "icons";
import Popup from "components/Popup.jsx";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import BlueBox1 from "components/BlueBox1.jsx";
import Buttonloader from "components/Buttonloader.jsx";
import NftGiffDownload from "components/popup/NftGiffDownload";
import EvolvePopupForEvolve2 from "components/popup/evolvePopupForEvolve2";
import StakePopupforEvolve2 from "components/popup/StakePopupforEvolve2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageLoader from "components/skeleton/ImageLoader";
import axios from "axios";

const DropdownButton = ({ url, fileName, phase }) => {
  return (
    <a
      href={url}
      download={fileName}
      className="block px-4 py-2 text-sm m-3 text-gray-700 bg-primary hover:bg-blue-300 rounded-lg shadow focus:outline-none focus:ring transition duration-150 ease-in-out text-[15px] text-white"
      style={{ width: "90px" }} // Ensures all buttons have the same width
    >
      {phase}
    </a>
  );
};

const DownloadButton = ({
  downloadLink,
  fileName,
  index,
  setActiveDropdown,
}) => {
  const phase = index + 1;
  const handleDownload = async () => {
    try {
      const response = await fetch(downloadLink);

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || "downloaded_image.gif";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL to free up resources
      window.URL.revokeObjectURL(url);
      setActiveDropdown(null);
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Error downloading the file:", error);
    }
  };

  return (
    <>
      <button className="my-2 mx-2 w-[87px]" onClick={handleDownload}>
        <div className="flex px-3 py-1 rounded-lg  bg-primary text-white items-center">
          Phase {phase}
        </div>
      </button>
    </>
  );
};
export default memo(function NFT({
  label,
  imageUrl,
  nft,
  stakeClickfrombackend,
  handleUnStakefrombackend,
  evolveafter30dayfrombackend,
  stakeloading,
  allGreen,
  checkout,
  isEvolving,
  loaderImageUrl,
  isScrolling,
}) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedtextpopup, setselectedtextpopup] = useState("");
  const [downloadGiffPopup, setDownloadGiffPopup] = useState(false);
  const [evolvenumber, setevolvenumber] = useState(1);
  const [showStakePopup, setShowStakePopup] = useState(false);
  const [showEvolvePopup, setShowEvolvePopup] = useState(false);
  const [showUnstake, setShowUnstake] = useState(false);
  // const [imageLoaded, setImageLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  // Use a state to track the active dropdown by its label
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [id, setid] = useState([]);

  const { address } = useAccount();
  const [giffLink, setGiffLink] = useState([]);

  const fetchgiff = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/nft/downloadgif`,
        {
          nftId: label,
        }
      );

      setGiffLink(response?.data?.data);
    } catch (error) {
      error.log("Error fetching transactions:", error);
      throw error;
    }
  };

  useEffect(() => {
    setid(address);
  }, [address]);

  const handleShowUnstake = () => {
    if (nft.dayCount) {
      setShowUnstake(true);
    }
  };
  const handleRemoveUnstack = () => {
    if (nft.dayCount) {
      setShowUnstake(false);
    }
  };

  const stakeClick = async () => {
    try {
      await stakeClickfrombackend(label, id);
    } catch (error) {}
  };

  const handleUnStake = async () => {
    try {
      handleUnStakefrombackend(label, id);
    } catch (error) {}
  };

  const evolveafter30day = async () => {
    try {
      await evolveafter30dayfrombackend(label, id);
    } catch (error) {}
  };

  const handleEvolveAndStakeButtonClick = () => {
    if (showUnstake) {
      setselectedtextpopup("unstack");
      setPopupOpen(true);
      return;
    }
    if (nft.evolveDayCount) {
      toast.error(
        `Evolution in process, you have to wait for ${nft.evolveDayCount} days to be a part of next evolution.`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  const handleClick = (currentLabel) => {
    setActiveDropdown((prevActiveDropdown) =>
      prevActiveDropdown === currentLabel ? null : currentLabel
    );
  };

  // Outside your component, likely at the top level of your module
  function isNotDropdownElement(element, dropdownRef) {
    return dropdownRef.current && !dropdownRef.current.contains(element);
  }

  const handleClickOutside = (event) => {
    if (
      activeDropdown !== null &&
      isNotDropdownElement(event.target, dropdownRef)
    ) {
      setActiveDropdown(null);
    }
  };
  let data = {
    evolution: (
      <div className="px-8 flex flex-col justify-center items-center mt-6">
        <p className="text-[#02071A] text-[16px] font-[500] ">
          Pay Monthly Evolution Fee{" "}
        </p>
        <p className="text-[#02071A] text-[36px] font-[600] py-6">$100</p>
        <div className="flex flex-col gap-4 items-center justify-start">
          <LazyLoadImage
            className="w-full h-full object-cover rounded-[16px] transition-all ease-in-out duration-500 transform-gpu"
            src={imageUrl}
            alt={label}
            frameRendering="auto"
          />
          <div className="w-full">
            <div className="flex items-center gap-[6px] justify-center">
              <p className="text-lg font-medium text-theme-gray">James</p>
              <TwitterTick />
            </div>
            <p className="text-base text-theme-black text-opacity-70 mt-1 text-center">
              @JamesCiesluk
            </p>
          </div>
        </div>
        <p className="text-center text-[#02071A] text-[16px] font-[300] py-6">
          Why{" "}
          <span className="text-[#02071A] text-[16px] font-[600] ">$100</span>?
          to evolve your Purple Square, you need to <br /> pay a processing fee
          of $100 unless you already hold <br /> 5 Purple Squares, in which case
          you can evolve them <br /> for free.
        </p>
        <Button className="py-3 mt-3 mb-5 px-6 rounded-[8px] flex-1 border bg-primary text-white">
          Checkout
        </Button>
      </div>
    ),
    unstack: (
      <div className=" p-0 pb-3 md:p-[36px] h-fit">
        <p className="text-[#02071A] text-center text-2xl md:text-[28px] font-[600] w-fit md:w-96 mx-auto">
          Are you sure you want to unstake your square?
        </p>
        <p className="text-[#02071A] pt-[46px] pb-[78px] text-xs md:text-[18px] font-[500] text-center">
          Keep it staked for {nft.dayCount} {nft.dayCount > 1 ? "days" : "day"}{" "}
          to evolve it free
        </p>
        <div className="flex items-center justify-between">
          <button
            onClick={() => setPopupOpen(false)}
            className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-3 md:px-[22px] py-3 md:py-[14px] text-sm md:text-[18px] font-[600]"
          >
            No, Keep Staked
          </button>
          <button
            onClick={() => {
              handleUnStake();
              setPopupOpen(false);
            }}
            className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-3 md:px-[22px] py-3 md:py-[14px] text-sm md:text-[18px] font-[600]"
          >
            Yes, Unstake
          </button>
        </div>
      </div>
    ),
    evolve: (
      <div className="p-[36px] h-fit">
        <p className="text-[#02071A] text-center text-[28px] font-[600]">
          Are you sure you want to <br /> Evolve your square?
        </p>

        <div className="space-x-[62px] mt-20">
          <button
            onClick={() => setPopupOpen(false)}
            className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-[22px] py-[14px] text-[18px] font-[600]"
          >
            Do not Evolve
          </button>
          <button
            onClick={() => {
              evolveafter30day();
              setPopupOpen(false);
            }}
            className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-[22px] py-[14px] text-[18px] font-[600]"
          >
            Yes, Evolve
          </button>
        </div>
      </div>
    ),
    sure: (
      <div className="p-0 pb-3 md:p-[36px] h-fit">
        <p className="text-[#02071A] text-center text-[25px] font-[600] w-[310px] md:w-96 mx-auto">
          Are you sure you want to Evolve your square?
        </p>

        <div className="flex items-center justify-between mt-20">
          <button
            onClick={() => setPopupOpen(false)}
            className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-3 md:px-[22px] py-3 md:py-[14px] text-[18px] font-[600]"
          >
            Do not Evolve
          </button>
          <button
            onClick={() => {
              evolveafter30day();
              setPopupOpen(false);
            }}
            className="rounded-[15px]  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-3 md:px-[22px] py-3 md:py-[14px] text-[18px] font-[600]"
          >
            Yes, Evolve
          </button>
        </div>
      </div>
    ),
    singlenft: (
      <div className="px-2 md:px-8 pb-3 md:pb-6">
        <p className=" flex justify-center items-center text-[#02071A] text-center text-xl md:text-[32px] font-[600]">
          You can’t evolve yet!
        </p>
        <div className="flex items-start w-full justify-between mt-6 md:mt-10">
          <div className="w-full">
            <h2 className="text-primary text-base md:text-xl font-semibold font-poppins text-center">
              Option 1: [Free]
            </h2>
            <p className="text-slate-950 text-base font-normal font-poppins mt-2 max-w-[340px] text-center">
              Stake your square for 30 days.
            </p>
          </div>
        </div>
        <div className="flex items-start w-full justify-between mt-6 md:mt-10">
          <div className="w-full">
            <h2 className="text-primary text-base md:text-xl font-semibold font-poppins text-center">
              Option 2: [Requires 5 Squares]
            </h2>
            <p className="text-slate-950 text-base font-normal font-poppins mt-2 max-w-[340px] text-center">
              Stake atleast 5 squares to unlock evolutions for all of your
              squares.
            </p>
          </div>
        </div>
        <div className="flex items-start w-full justify-between mt-6 md:mt-14">
          <div className="w-full">
            <h2 className="text-primary text-base md:text-xl font-semibold font-poppins text-center">
              Option 3: [Evolution Rights]
            </h2>
            <p className="text-slate-950 text-base font-normal font-poppins mt-2 max-w-[340px] text-center">
              You can pay to evolve instantly if you stake less than 5 squares
              and don't want to wait the 30 day free stake period.
            </p>
          </div>
        </div>
        <div className="flex mt-6 md:mt-12 justify-between items-center">
          <button
            onClick={() => setPopupOpen(false)}
            className=" rounded-[15px] border-[2px] border-[#02071A] opacity-50 text-[#02071A] px-4 md:px-6 py-1.5 md:py-2.5 text-base font-[600]"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setselectedtextpopup("paynow");
            }}
            className="rounded-[15px]  bg-primary  border-[2.5px] border-primary  text-[#FFF] px-4 md:px-6 py-1.5 md:py-2.5 text-base font-[600]"
          >
            Pay to Evolve
          </button>
        </div>
      </div>
    ),
    paynow: (
      <div className="flex flex-col justify-center items-center px-2 md:px-10 pb-6">
        <p className="text-[#02071A] text-center text-[20px] mt-1 font-medium">
          Evolve Instantly
        </p>
        <p className="text-[#02071A] text-center text-sm mt-1 max-w-sm">
          Unlock the ability to instantly evolve your squares for the next 30
          days.
        </p>
        <p className=" flex justify-center mt-4 mb-2 items-center text-[#02071A] text-center text-[32px]  font-[600]">
          $100
        </p>
        {evolvenumber == 1 && (
          <div className="flex justify-center items-center mt-8 ">
            <motion.div
              initial={{ opacity: 0, x: -50, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
            >
              <Button className="sm:text-[15px] text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 bg-[#EF284C] text-white">
                Evolve
              </Button>
              {/* <BlueBox1 src={null} /> */}
            </motion.div>
            <motion.img
              initial={{ opacity: 0, x: -70, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              className="w-[40px] mx-3 object-contain"
              viewport={{ once: true }}
              transition={{ duration: 0.4 }}
              src="/go.svg"
              alt=""
              srcset=""
            />
            <motion.div
              initial={{ opacity: 0, x: -90, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <Button className="sm:text-[15px] text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 bg-green-400 text-white  px-2 md:px-3">
                Evolve
              </Button>
            </motion.div>
          </div>
        )}
        {evolvenumber == 2 && (
          <div className="flex justify-center items-center mt-8 ">
            <motion.div
              initial={{ opacity: 0, x: -50, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
            >
              <BlueBox1 src="/square_2.gif" />
            </motion.div>
            <motion.img
              initial={{ opacity: 0, x: -70, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              className="w-[40px] mx-3 object-contain"
              viewport={{ once: true }}
              transition={{ duration: 0.4 }}
              src="/go.svg"
              alt=""
              srcset=""
            />
            <motion.div
              initial={{ opacity: 0, x: -90, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <BlueBox1 src="/square_3.gif" />
            </motion.div>
          </div>
        )}
        {evolvenumber == 3 && (
          <div className="flex justify-center items-center mt-8 ">
            <motion.div
              initial={{ opacity: 0, x: -50, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
            >
              <BlueBox1 src="/square_3.gif" />
            </motion.div>
            <motion.img
              initial={{ opacity: 0, x: -70, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              className="w-[40px] mx-3 object-contain"
              viewport={{ once: true }}
              transition={{ duration: 0.4 }}
              src="/go.svg"
              alt=""
              srcset=""
            />
            <motion.div
              initial={{ opacity: 0, x: -90, rotate: 0 }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <BlueBox1 src="/square_4.gif" />
            </motion.div>
          </div>
        )}
        <button
          onClick={() => {
            checkout();
            setPopupOpen(false);
          }}
          className="rounded-[15px] mt-8 flex justify-center items-center  bg-primary  border-[2.5px] border-primary   text-[#FFF] px-[62px] py-[14px] text-[18px] font-[600]"
        >
          Pay Now
        </button>
      </div>
    ),
  };

  useEffect(() => {
    // Wait for 1-2 seconds before hiding the loader
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchgiff();
  }, []);

  useEffect(() => {
    const clickHandler = (event) => handleClickOutside(event);

    document.addEventListener("mousedown", clickHandler);

    return () => {
      document.removeEventListener("mousedown", clickHandler);
    };
  }, [activeDropdown]);

  return (
    <div className="p-4 w-[145px] justify-center md:items-start items-center  sm:w-[220px] bg-white h-max rounded-2xl flex flex-col sm:gap-2">
      <div className="w-full bg-white  md:justify-start md:items-start justify-center items-center flex gap-2 flex-col">
        <div className="relative w-[110px] h-[110px] sm:w-full sm:h-[180px] bg-primary rounded-[16px]">
          {showLoader ? (
            <ImageLoader />
          ) : (
            <>
              <button
                onClick={() => handleClick(label)}
                className="absolute top-2 right-2 z-10 flex items-center justify-center w-9 h-9 bg-white rounded-lg shadow focus:outline-none focus:ring"
              >
                <svg
                  fill="none"
                  height="32"
                  viewBox="0 0 32 32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="#fff" height="32" rx="8" width="32" />
                  <path
                    d="m11.9993 29.3332h8c6.6667 0 9.3334-2.6667 9.3334-9.3334v-8c0-6.66663-2.6667-9.3333-9.3334-9.3333h-8c-6.66662 0-9.33328 2.66667-9.33328 9.3333v8c0 6.6667 2.66666 9.3334 9.33328 9.3334z"
                    fill="#fff"
                  />
                  <g
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  >
                    <path d="m12 15.3467 4 4 4-4" />
                    <path d="m16 19.3468v-10.66662" />
                    <path d="m8 22.0137c5.1867 1.7333 10.8133 1.7333 16 0" />
                  </g>
                </svg>
              </button>
              {activeDropdown === label && (
                <div className="absolute z-10 w-[105px] top-11 right-10 origin-top-right bg-white  rounded-lg shadow-lg outline-none rounded-tr-sm">
                  {giffLink?.map((link, index) => (
                    <DownloadButton
                      key={index} // Added a unique key prop using the index
                      downloadLink={link}
                      fileName={`627EEA_NFT${label}_Phase${index + 1}`}
                      index={index}
                      setActiveDropdown={setActiveDropdown}
                    />
                  ))}
                </div>
              )}
              <div
                onClick={() => setDownloadGiffPopup(true)}
                className="w-[110px] h-[110px] flex justify-center items-center sm:w-full sm:h-[180px] bg-primary rounded-[16px] cursor-pointer"
              >
                <img
                  className="w-full h-full object-cover rounded-[16px] transition-all ease-in-out duration-500 transform-gpu"
                  src={isEvolving && loaderImageUrl ? loaderImageUrl : imageUrl}
                  alt={label}
                  frameRendering="auto"
                />
              </div>
            </>
          )}
        </div>
        <p className="font-[500] whitespace-nowrap text-[11px] sm:text-[15px] text-base text-theme-black">
          {`627EEA #${label}`}
        </p>
      </div>
      <div className="flex md:w-full w-[133px] gap-x-1 sm:gap-x-3 justify-between items-center mt-[7px] md:mt-0">
        {!allGreen &&
          nft.stakeStatus == null &&
          (nft.evolveStatus === null || nft.evolveStatus === false) && (
            <>
              <Button
                onClick={stakeClick}
                disabled={stakeloading}
                className="sm:text-[15px] text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 border-primary bg-white text-primary "
              >
                {stakeloading === label ? <Buttonloader /> : "Stake"}
              </Button>
              {allGreen && nft.evolveStatus != true ? (
                <Button
                  onClick={() => {
                    if (nft?.evolveCount === 3) {
                      setShowEvolvePopup(true);
                    } else {
                      setselectedtextpopup("sure");
                      setPopupOpen(true);
                    }
                  }}
                  className="sm:text-[15px] text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 bg-green-400 text-white"
                >
                  Evolve
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (nft?.evolveCount === 3) {
                      setShowEvolvePopup(true);
                    } else {
                      setselectedtextpopup("singlenft");
                      setPopupOpen(true);
                    }
                  }}
                  className="sm:text-[15px] text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 bg-[#EF284C] text-white"
                >
                  Evolve
                </Button>
              )}
            </>
          )}

        {((nft.dayCount > 0 && !allGreen) ||
          (nft.evolveDayCount > 0 && nft.evolveStatus == true)) && (
          <div className="w-full">
            <Button
              onClick={handleEvolveAndStakeButtonClick}
              onMouseEnter={handleShowUnstake}
              onMouseLeave={handleRemoveUnstack}
              className={`sm:text-[15px] w-full sm:py-[7px] py-1 rounded-[8px] text-[12px] flex-1 border-2 border-primary bg-white text-primary ${
                nft.dayCount ? "" : "px-0"
              }`}
            >
              {showUnstake ? (
                stakeloading === label ? (
                  <Buttonloader />
                ) : (
                  "Unstake"
                )
              ) : nft.dayCount ? (
                `${nft.dayCount} ${nft.dayCount > 1 ? "days" : "day"} left...`
              ) : (
                `Evolve in ${nft.evolveDayCount} ${
                  nft.evolveDayCount > 1 ? "days" : "day"
                }...`
              )}
            </Button>
          </div>
        )}
        {(nft.dayCount === 0 || allGreen) &&
          (nft.evolveDayCount === null || nft.evolveDayCount === 0) && (
            <>
              <Button
                onClick={() => {
                  if (nft.stakeStatus) {
                    setselectedtextpopup("unstack");
                    setPopupOpen(true);
                  } else {
                    toast.error(
                      "You are unable to stake until you have completed the evolution process.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  }
                }}
                className="sm:text-[15px] w-full text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 border-primary bg-white text-primary px-2 md:px-3"
              >
                {stakeloading === label ? (
                  <Buttonloader />
                ) : nft.stakeStatus ? (
                  "Unstake"
                ) : (
                  "Stake"
                )}
              </Button>
              <Button
                onClick={() => {
                  if (nft?.evolveCount === 3) {
                    setShowEvolvePopup(true);
                  } else {
                    setselectedtextpopup("sure");
                    setPopupOpen(true);
                  }
                }}
                className="sm:text-[15px] text-[12px] sm:py-[7px] py-1 rounded-[8px] flex-1 border-2 bg-green-400 text-white  px-2 md:px-3"
              >
                Evolve
              </Button>
            </>
          )}
      </div>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        children={data[selectedtextpopup]}
      />
      {downloadGiffPopup && (
        <NftGiffDownload
          imageUrl={imageUrl}
          label={label}
          setDownloadGiffPopup={setDownloadGiffPopup}
        />
      )}
      {showEvolvePopup && (
        <EvolvePopupForEvolve2 setShowEvolvePopup={setShowEvolvePopup} />
      )}
      {showStakePopup && (
        <StakePopupforEvolve2 setShowStakePopup={setShowStakePopup} />
      )}
    </div>
  );
});
