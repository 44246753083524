import React from "react";
import { motion } from "framer-motion";

function CommunityCard({ t1, imge, t2 }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100, rotate: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className={`bg-[#FFF] w-[100%]  md:w-[400px] h-[470px] rounded-[24px] ${
        imge === "/sl.svg" ? "" : ""
      }`}
    >
      <motion.div
        initial={{ opacity: 0, x: -200, rotate: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className="p-[24px]"
      >
        <p className="text-[#02071A] text-[24px] font-[600] mb-[16px]">{t1}</p>
        <div className="w-full n1 h-[220px] p-[32px] rounded-[24px] bg-[#EFEFF3]">
          <img
            className={`object-contain  ${
              imge == "/sl.svg" ? "mb-[22px] ml-[22px]" : ""
            }`}
            src={imge}
            alt=""
            srcset=""
          />
        </div>
        <p className="text-[#02071A] mt-[12px] font-[400] text-[16px] opacity-[0.7]">
          {t2}
        </p>
      </motion.div>
    </motion.div>
  );
}

export default CommunityCard;
