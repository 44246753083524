export default function TwitterTick() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.76302 0.823877C6.21064 1.29461 5.52384 1.57909 4.80039 1.63683C3.11215 1.77155 1.77155 3.11215 1.63683 4.80039C1.57909 5.52384 1.29461 6.21064 0.823877 6.76302C-0.274626 8.05205 -0.274626 9.94795 0.823877 11.237C1.29461 11.7894 1.57909 12.4762 1.63683 13.1996C1.77155 14.8879 3.11215 16.2285 4.80039 16.3632C5.52384 16.4209 6.21064 16.7054 6.76302 17.1761C8.05205 18.2746 9.94795 18.2746 11.237 17.1761C11.7894 16.7054 12.4762 16.4209 13.1996 16.3632C14.8879 16.2285 16.2285 14.8879 16.3632 13.1996C16.4209 12.4762 16.7054 11.7894 17.1761 11.237C18.2746 9.94795 18.2746 8.05205 17.1761 6.76302C16.7054 6.21064 16.4209 5.52384 16.3632 4.80039C16.2285 3.11215 14.8879 1.77155 13.1996 1.63683C12.4762 1.57909 11.7894 1.29461 11.237 0.823877C9.94795 -0.274626 8.05205 -0.274626 6.76302 0.823877Z"
        fill="#1D9BF0"
      />
      <path
        d="M6.35298 9.13232L8.15298 10.9323L12.203 6.88232"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
