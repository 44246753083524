import React from "react";
import { ThreeDots } from "react-loader-spinner";

function Buttonloader() {
  return (
    <div>
      <ThreeDots
        height="16"
        width="35"
        radius="9"
        color="#627EEA"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
}

export default Buttonloader;
